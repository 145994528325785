import { HOST } from '../Constants.ts';
function useFetch() {
    return async (uri, options) => {
        const response = await fetch(`${HOST}${uri}`, {
            ...options,
            headers: options
                ? {
                    ...options.headers,
                    Accept: 'application/json',
                }
                : {
                    Accept: 'application/json',
                },
        });
        if (response.ok) {
            let json = await response.json();
            return json;
        }
        else {
            if (response.status === 401) {
                window.location.href = `${HOST}/login.html`;
            }
            let error, json;
            try {
                json = await response.json();
            }
            catch (e) { }
            if (json && json.message) {
                error = new Error(json.message);
            }
            else {
                error = new Error(response.statusText);
            }
            return Promise.reject(error);
        }
    };
}
export default useFetch;
