import { jsx as _jsx } from "react/jsx-runtime";
import NotFound from './NotFound';
import Index from './pages/Index';
import SelectTemplate from './pages/SelectTemplate/Index.tsx';
import EditTemplate from './pages/EditTemplate/Index.tsx';
import IntegrateTemplate from './pages/IntegrateTemplate/Index.tsx';
import Subscription from './pages/Subscription/Index.tsx';
// import Login from './pages/Login';
// import Subscribe from './pages/Subscribe/Index.tsx';
// import PromocodeModal from './modals/Promocode.tsx';
// import PreviewTemplateModal from './modals/PreviewTemplate.tsx';
// import EditTheme from './pages/EditTheme/Index.tsx';
// import WaitTheme from './pages/WaitTheme/Index.tsx';
// import EditCard from './pages/EditCard/Index.tsx';
const routes = {
    '/': () => _jsx(Index, {}),
    '/select_template': () => _jsx(SelectTemplate, {}),
    '/edit_template': () => _jsx(EditTemplate, {}),
    '/edit_template/*': () => _jsx(EditTemplate, {}),
    '/integrate_template/*': () => _jsx(IntegrateTemplate, {}),
    '/subscription': () => _jsx(Subscription, {}),
    // '/login': () => <Login />,
    // '/subscribe': () => <Subscribe />,
    // '/select_template': () => <SelectTemplate />,
    // '/edit_theme': () => <EditTheme />,
    // '/edit_card': () => <EditCard />,
    // '/wait_theme': () => <WaitTheme />,
    // '/promocode': () => <PromocodeModal />,
    // '/preview_template': () => (
    //   <PreviewTemplateModal template_state={undefined} />
    // ),
    '*': () => _jsx(NotFound, {}),
};
export default routes;
