import { useMemo } from 'react';
import useFetch from './useFetch.ts';
export function useImageFileUploader() {
    const fetch = useFetch();
    const uploader = useMemo(function () {
        return function (file) {
            const formData = new FormData();
            // @ts-ignore
            if (file.name) {
                formData.append('image', file, file.name);
            }
            else {
                formData.append('image', file);
            }
            return fetch(`/api/user/image/upload`, {
                method: 'POST',
                body: formData,
                headers: {},
            }).then((answer) => answer.link);
        };
    }, [fetch]);
    return uploader;
}
