import { jsx as _jsx } from "react/jsx-runtime";
import React, { createContext, useCallback, useReducer, } from 'react';
import { ReducerTypes, } from './types';
import { DrawActionTypes, drawReducer, } from './reducers/drawReducer';
import { CanvaZoomChangeActionType, canvaZoomReducer, } from './reducers/canvasReducer';
import { FontActionTypes, fontReducer, } from './reducers/fontsReducer';
import { SelectedItemActionsEnum, SelectedItemActionTypes, selectedItemReducer, } from './reducers/selectedItemReducer';
const initialState = {
    drawItems: [],
    canvaZoom: 1,
    selectedItem: {
        id: -1,
        inFocus: false,
        currentAction: SelectedItemActionsEnum.Idle,
        actionStartedRect: { x: 0, y: 0, width: 0, height: 0 },
        actionStartedMousePosition: { x: 0, y: 0 },
    },
    fonts: require('./avaiableFonts.json').sort((a, b) => a.fontFamily > b.fontFamily ? 1 : -1),
};
const EditorContext = createContext({
    state: initialState,
    dispatch: () => null,
    uploadImageCallback: (file) => Promise.reject(new Error('Not provided')),
    errorModalCallback: (err) => null,
});
const mainReducer = ({ drawItems, canvaZoom, selectedItem, fonts }, action) => ({
    drawItems: action.reducerType === ReducerTypes.DrawReducer
        ? drawReducer(drawItems, action)
        : drawItems,
    canvaZoom: action.reducerType === ReducerTypes.CanvaZoomReducer
        ? canvaZoomReducer(canvaZoom, action)
        : canvaZoom,
    selectedItem: action.reducerType === ReducerTypes.SelectedItemReducer
        ? selectedItemReducer(selectedItem, action)
        : selectedItem,
    fonts: action.reducerType === ReducerTypes.FontReducer
        ? fontReducer(fonts, action)
        : fonts,
});
const EditorProvider = ({ children, uploadImageCallback, errorModalCallback, }) => {
    // @ts-ignore
    const [state, dispatch] = useReducer(mainReducer, initialState);
    return (_jsx(EditorContext.Provider, { value: {
            state,
            dispatch,
            uploadImageCallback,
            errorModalCallback,
        }, children: children }));
};
const useDrawItemsWithActions = function () {
    const { state, dispatch } = React.useContext(EditorContext);
    const addNewItem = useCallback((item) => {
        let newItemId = state.drawItems.reduce((maxId, current) => (current.id >= maxId ? current.id : maxId), 0) + 1;
        dispatch({
            reducerType: ReducerTypes.DrawReducer,
            type: DrawActionTypes.Create,
            payload: { item: item },
        });
        //TODO think about better auto select for new items.
        dispatch({
            reducerType: ReducerTypes.SelectedItemReducer,
            type: SelectedItemActionTypes.Select,
            payload: {
                drawId: newItemId,
            },
        });
    }, [dispatch, state.drawItems.length]);
    const changeItem = useCallback((id, item) => {
        dispatch({
            reducerType: ReducerTypes.DrawReducer,
            type: DrawActionTypes.Change,
            payload: { id: id, item: item },
        });
    }, [dispatch]);
    const deleteItem = useCallback((id) => {
        dispatch({
            reducerType: ReducerTypes.DrawReducer,
            type: DrawActionTypes.Delete,
            payload: { id: id },
        });
    }, [dispatch]);
    const moveItemInLayers = useCallback((drawId, direction) => {
        dispatch({
            reducerType: ReducerTypes.DrawReducer,
            type: DrawActionTypes.MoveInLayers,
            payload: {
                id: drawId,
                direction: direction,
            },
        });
    }, [dispatch]);
    // return state.drawItems;
    return {
        drawItems: state.drawItems,
        addNewItem,
        changeItem,
        deleteItem,
        moveItemInLayers,
    };
};
const useChangeDrawItem = function () {
    const { dispatch } = React.useContext(EditorContext);
    const changeItem = useCallback((id, item) => {
        dispatch({
            reducerType: ReducerTypes.DrawReducer,
            type: DrawActionTypes.Change,
            payload: { id: id, item: item },
        });
    }, [dispatch]);
    const modifyItemProperty = useCallback((drawId, propName, propValue) => {
        dispatch({
            reducerType: ReducerTypes.DrawReducer,
            type: DrawActionTypes.ChangeProperty,
            payload: {
                id: drawId,
                propName,
                propValue,
            },
        });
    }, [dispatch]);
    return { changeItem, modifyItemProperty };
};
const useCanvaZoom = function () {
    const { state } = React.useContext(EditorContext);
    return { canvaZoom: state.canvaZoom };
};
const useCanvaZoomWithAction = function () {
    const { state, dispatch } = React.useContext(EditorContext);
    const changeCanvaZoom = useCallback((value) => {
        dispatch({
            reducerType: ReducerTypes.CanvaZoomReducer,
            type: CanvaZoomChangeActionType,
            payload: value,
        });
    }, [dispatch]);
    return { canvaZoom: state.canvaZoom, changeCanvaZoom };
};
const useSelectedItemWithActions = function () {
    const { state, dispatch } = React.useContext(EditorContext);
    const setSelectedItemId = useCallback((value) => {
        dispatch({
            reducerType: ReducerTypes.SelectedItemReducer,
            type: SelectedItemActionTypes.Select,
            payload: {
                drawId: value,
            },
        });
    }, [dispatch]);
    const stopSelectedItemAction = useCallback(() => {
        dispatch({
            reducerType: ReducerTypes.SelectedItemReducer,
            type: SelectedItemActionTypes.StopAction,
        });
    }, [dispatch]);
    const moveSelectedItem = useCallback((toCoords) => {
        dispatch({
            reducerType: ReducerTypes.DrawReducer,
            type: DrawActionTypes.Move,
            payload: {
                id: state.selectedItem.id,
                newPosition: toCoords,
            },
        });
    }, [dispatch, state.selectedItem.id]);
    const resizeSelectedItem = useCallback((toRect) => {
        dispatch({
            reducerType: ReducerTypes.DrawReducer,
            type: DrawActionTypes.Resize,
            payload: {
                id: state.selectedItem.id,
                newRect: toRect,
            },
        });
    }, [dispatch, state.selectedItem.id]);
    const rotateSelectedItem = useCallback((newRotation) => {
        dispatch({
            reducerType: ReducerTypes.DrawReducer,
            type: DrawActionTypes.Rotate,
            payload: {
                id: state.selectedItem.id,
                newRotation: newRotation,
            },
        });
    }, [dispatch, state.selectedItem.id]);
    const startItemResizeAction = useCallback((drawId, resizeType, mouseDownCoords, startedRect) => {
        dispatch({
            reducerType: ReducerTypes.SelectedItemReducer,
            type: SelectedItemActionTypes.StartResizeAction,
            payload: {
                drawId: drawId,
                resizeType: resizeType,
                startedMousePosition: mouseDownCoords,
                startedRect: startedRect,
            },
        });
    }, [dispatch]);
    const startItemMoveAction = useCallback((drawId, mouseDownCoords, startedRect) => {
        dispatch({
            reducerType: ReducerTypes.SelectedItemReducer,
            type: SelectedItemActionTypes.StartMoveAction,
            payload: {
                drawId: drawId,
                startedMousePosition: mouseDownCoords,
                startedRect: startedRect,
            },
        });
    }, [dispatch]);
    const startItemRotationAction = useCallback((drawId, mouseDownCoords) => {
        dispatch({
            reducerType: ReducerTypes.SelectedItemReducer,
            type: SelectedItemActionTypes.StartRotationAction,
            payload: {
                drawId: drawId,
                startedMousePosition: mouseDownCoords,
            },
        });
    }, [dispatch]);
    const modifyItemProperty = useCallback((drawId, propName, propValue) => {
        dispatch({
            reducerType: ReducerTypes.DrawReducer,
            type: DrawActionTypes.ChangeProperty,
            payload: {
                id: drawId,
                propName,
                propValue,
            },
        });
    }, [dispatch]);
    const onFocus = useCallback(() => {
        dispatch({
            reducerType: ReducerTypes.SelectedItemReducer,
            type: SelectedItemActionTypes.Focus,
        });
    }, [dispatch]);
    const onBlur = useCallback(() => {
        dispatch({
            reducerType: ReducerTypes.SelectedItemReducer,
            type: SelectedItemActionTypes.Blur,
        });
    }, [dispatch]);
    return {
        selectedItem: state.selectedItem.id !== -1
            ? state.drawItems.find((d) => d.id === state.selectedItem.id)
            : undefined,
        selectedItemAction: state.selectedItem.currentAction,
        selectedItemActionStartedMousePosition: state.selectedItem.actionStartedMousePosition,
        selectedItemActionStartedRect: state.selectedItem.actionStartedRect,
        setSelectedItemId,
        stopSelectedItemAction,
        moveSelectedItem,
        resizeSelectedItem,
        rotateSelectedItem,
        startItemResizeAction,
        startItemMoveAction,
        startItemRotationAction,
        modifyItemProperty,
        isOnFocus: !!state.selectedItem && state.selectedItem.inFocus,
        onFocus,
        onBlur,
    };
};
const useFontsWithActions = function () {
    const { state, dispatch } = React.useContext(EditorContext);
    const loadFont = useCallback((family, weight) => {
        dispatch({
            reducerType: ReducerTypes.FontReducer,
            type: FontActionTypes.Load,
            payload: {
                fontFamily: family,
                weight: weight,
                asyncOnLoad: () => dispatch({
                    reducerType: ReducerTypes.FontReducer,
                    type: FontActionTypes.Loaded,
                    payload: {
                        fontFamily: family,
                        weight: weight,
                    },
                }),
            },
        });
    }, [dispatch]);
    return { fonts: state.fonts, loadFont };
};
const useDeselectItemAction = function () {
    const { dispatch } = React.useContext(EditorContext);
    const deselectedItem = useCallback(() => {
        dispatch({
            reducerType: ReducerTypes.SelectedItemReducer,
            type: SelectedItemActionTypes.Deselect,
        });
    }, [dispatch]);
    return deselectedItem;
};
const useSerialize = function () {
    const { state, dispatch } = React.useContext(EditorContext);
    const serialize = useCallback(() => {
        return JSON.parse(JSON.stringify(state));
    }, [state, state.drawItems, dispatch]);
    return serialize;
};
const useDeserialize = function () {
    const { state, dispatch } = React.useContext(EditorContext);
    const deserialize = useCallback((data) => {
        return dispatch({
            reducerType: ReducerTypes.DrawReducer,
            type: DrawActionTypes.LoadState,
            payload: { data: data },
        });
    }, [dispatch]);
    return deserialize;
};
const useImageUploadCallback = function () {
    const { uploadImageCallback } = React.useContext(EditorContext);
    return uploadImageCallback;
};
const useErrorModalCallback = function () {
    const { errorModalCallback } = React.useContext(EditorContext);
    return errorModalCallback;
};
export { EditorProvider, EditorContext, useDrawItemsWithActions, useCanvaZoom, useCanvaZoomWithAction, useSelectedItemWithActions, useDeselectItemAction, useChangeDrawItem, useFontsWithActions, useSerialize, useDeserialize, useImageUploadCallback, useErrorModalCallback, };
