export var DrawActionTypes;
(function (DrawActionTypes) {
    DrawActionTypes["Create"] = "CREATE_DRAW";
    DrawActionTypes["Delete"] = "DELETE_DRAW";
    DrawActionTypes["Change"] = "CHANGE_DRAW";
    DrawActionTypes["ChangeProperty"] = "CHANGE_DRAW_PROPERTY";
    DrawActionTypes["Move"] = "MOVE_DRAW";
    DrawActionTypes["Resize"] = "RESIZE_DRAW";
    DrawActionTypes["Rotate"] = "ROTATE_DRAW";
    DrawActionTypes["MoveInLayers"] = "MOVE_IN_LAYERS";
    DrawActionTypes["LoadState"] = "LOAD_STATE";
})(DrawActionTypes || (DrawActionTypes = {}));
export const drawReducer = (state, action) => {
    switch (action.type) {
        case DrawActionTypes.Create:
            return [
                ...state,
                {
                    ...action.payload.item,
                    //calculate new item ID by search for maxId in array
                    id: state.reduce((maxId, current) => current.id >= maxId ? current.id : maxId, 0) + 1
                }
            ];
        case DrawActionTypes.Move:
            return state.map(draw => {
                if (draw.id === action.payload.id) {
                    return { ...draw, position: { x: Math.floor(action.payload.newPosition.x), y: Math.floor(action.payload.newPosition.y) } };
                }
                else {
                    return draw;
                }
            });
        case DrawActionTypes.Resize:
            return state.map(draw => {
                if (draw.id === action.payload.id) {
                    return { ...draw,
                        position: { x: Math.floor(action.payload.newRect.x), y: Math.floor(action.payload.newRect.y) },
                        size: { width: Math.floor(action.payload.newRect.width), height: Math.floor(action.payload.newRect.height) }
                    };
                }
                else {
                    return draw;
                }
            });
        case DrawActionTypes.Rotate:
            return state.map(draw => {
                if (draw.id === action.payload.id) {
                    return { ...draw,
                        rotationAngle: action.payload.newRotation
                    };
                }
                else {
                    return draw;
                }
            });
        case DrawActionTypes.Delete:
            return [...state.filter(draw => draw.id !== action.payload.id)];
        case DrawActionTypes.Change:
            return state.map(draw => {
                if (draw.id === action.payload.id) {
                    return action.payload.item;
                }
                else {
                    return draw;
                }
            });
        case DrawActionTypes.ChangeProperty:
            return state.map(draw => {
                if (draw.id === action.payload.id) {
                    // @ts-ignore
                    if (action.payload.propName in draw && typeof action.payload.propValue === typeof draw[action.payload.propName]) {
                        let newDraw = { ...draw };
                        // @ts-ignore
                        newDraw[action.payload.propName] = action.payload.propValue;
                        return newDraw;
                    }
                    else if (action.payload.propName === 'wrap') {
                        let newDraw = { ...draw };
                        // @ts-ignore
                        newDraw[action.payload.propName] = action.payload.propValue;
                        return newDraw;
                    }
                    else {
                        return draw;
                    }
                }
                else {
                    return draw;
                }
            });
        case DrawActionTypes.MoveInLayers:
            let item = state.find(item => item.id === action.payload.id);
            if (item) {
                if (action.payload.direction === 'up' && state.length - 1 > state.indexOf(item)) {
                    return moveItemInArrayFromIndexToIndex(state, state.indexOf(item), state.indexOf(item) + 1);
                }
                else if (action.payload.direction === 'down' && state.indexOf(item) > 0) {
                    return moveItemInArrayFromIndexToIndex(state, state.indexOf(item), state.indexOf(item) - 1);
                }
                else {
                    return state;
                }
            }
            else {
                return state;
            }
        case DrawActionTypes.LoadState:
            return action.payload.data;
        default:
            return state;
    }
};
const moveItemInArrayFromIndexToIndex = (array, fromIndex, toIndex) => {
    if (fromIndex === toIndex)
        return array;
    const newArray = [...array];
    const target = newArray[fromIndex];
    const inc = toIndex < fromIndex ? -1 : 1;
    for (let i = fromIndex; i !== toIndex; i += inc) {
        newArray[i] = newArray[i + inc];
    }
    newArray[toIndex] = target;
    return newArray;
};
