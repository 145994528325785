import { jsx as _jsx } from "react/jsx-runtime";
import Slider, { SliderTooltip } from 'rc-slider';
import 'rc-slider/assets/index.css';
import React from "react";
import { useCanvaZoomWithAction } from "../../context";
const { Handle } = Slider;
const handle = (props) => {
    const { value, dragging, index, ...restProps } = props;
    return (_jsx(SliderTooltip, { prefixCls: "rc-slider-tooltip", overlay: `${value}`, visible: dragging, placement: "bottom", children: _jsx(Handle, { value: value, ...restProps }) }, index));
};
const wrapperStyle = { width: '100%', margin: 0 };
export const ZoomSlider = function ZoomSlider() {
    const { canvaZoom, changeCanvaZoom } = useCanvaZoomWithAction();
    return _jsx("div", { style: wrapperStyle, children: _jsx(Slider, { step: 0.1, min: 0.2, max: 2, value: canvaZoom, onChange: (value) => changeCanvaZoom(value), handle: (props) => handle(props) }) });
};
