import useFetch from './useFetch.ts';
import { useCallback } from 'react';
export const useSubscribeRequest = function () {
    const fetcher = useFetch();
    return useCallback(function (name) {
        return fetcher(`/api/user/subscribe`, {
            method: 'POST',
            body: JSON.stringify({
                name,
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }, [fetcher]);
};
