import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Box, Button, Card, Icon, InlineStack, Text, Tooltip, } from '@shopify/polaris';
import { CheckCircleIcon, QuestionCircleIcon } from '@shopify/polaris-icons';
import { useI18n } from '@shopify/react-i18n';
import { useStore } from '../../../stores';
import { RenderCountsForPlan, SubscriptionPlanEnum, SubscriptionPrices, TemplatesCountsForPlan, UserPlanToS3ErrorRequestPerMonthCount, } from '../../../types';
import './PlanCard.scss';
const PlanCard = ({ name, activatePlanCallback, }) => {
    const [i18n] = useI18n();
    const onPlan = useStore((state) => state.user.on_plan);
    const currentPlan = onPlan === name;
    const boxPadding = '100';
    function purchase() {
        return activatePlanCallback(name);
    }
    return (_jsx(Card, { background: 'bg-surface', children: _jsx(Box, { width: "300", padding: boxPadding, children: _jsxs("div", { style: {
                    display: 'flex',
                    height: '260px',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                }, children: [_jsxs("div", { children: [_jsxs(Text, { alignment: 'center', variant: "headingLg", as: 'p', children: [name, " plan"] }), _jsxs(Text, { variant: "headingLg", alignment: 'center', as: 'p', children: ["$", SubscriptionPrices[name], " per month"] })] }), _jsxs("div", { children: [_jsxs(InlineStack, { align: 'start', blockAlign: "start", children: [_jsx(Box, { as: 'div', width: '20', children: _jsx(Icon, { source: CheckCircleIcon }) }), _jsx(Box, { as: 'div', width: '260', children: _jsx(Text, { variant: "bodyMd", as: "p", children: _jsxs(Tooltip, { content: "We count only unique renders. Repeatable requests will be cached for a month and will not be count. Also we will not instantly stopping service if you reached the limit. It mostly for baseline but it is okay to make more renders sometimes.", children: [RenderCountsForPlan[name], " unique renders per month", _jsx("div", { style: {
                                                            display: 'inline-block',
                                                            verticalAlign: 'bottom',
                                                            width: '20px',
                                                        }, children: _jsx(Icon, { source: QuestionCircleIcon }) })] }) }) })] }), _jsxs(InlineStack, { align: 'start', blockAlign: "start", children: [_jsx(Box, { as: 'div', width: '20', children: _jsx(Icon, { source: CheckCircleIcon }) }), _jsx(Box, { as: 'div', width: '260', children: _jsxs(Text, { variant: "bodyMd", as: "p", children: ["Up to ", TemplatesCountsForPlan[name], " active templates"] }) })] }), _jsxs(InlineStack, { align: 'start', blockAlign: "start", children: [_jsx(Box, { as: 'div', width: '20', children: _jsx(Icon, { source: CheckCircleIcon }) }), _jsx(Box, { as: 'div', width: '260', children: _jsx(Text, { variant: "bodyMd", as: "p", children: _jsxs(Tooltip, { content: `Baseline is ${UserPlanToS3ErrorRequestPerMonthCount[name]} repeatable requests for cached images.`, children: ["Fair use cdn", _jsx("div", { style: {
                                                            display: 'inline-block',
                                                            verticalAlign: 'bottom',
                                                            width: '20px',
                                                        }, children: _jsx(Icon, { source: QuestionCircleIcon }) })] }) }) })] }), name === SubscriptionPlanEnum.Basic && (_jsxs(InlineStack, { align: 'start', blockAlign: "start", children: [_jsx(Box, { as: 'div', width: '20', children: _jsx(Icon, { source: CheckCircleIcon }) }), _jsx(Box, { as: 'div', width: '260', children: _jsx(Text, { variant: "bodyMd", as: "p", children: "Good for small e-stores and blogs" }) })] })), name === SubscriptionPlanEnum.Business && (_jsxs(InlineStack, { align: 'start', blockAlign: "start", children: [_jsx(Box, { as: 'div', width: '20', children: _jsx(Icon, { source: CheckCircleIcon }) }), _jsx(Box, { as: 'div', width: '260', children: _jsx(Text, { variant: "bodyMd", as: "p", children: "Faster cdn" }) })] })), name === SubscriptionPlanEnum.Business && (_jsxs(InlineStack, { align: 'start', blockAlign: "start", children: [_jsx(Box, { as: 'div', width: '20', children: _jsx(Icon, { source: CheckCircleIcon }) }), _jsx(Box, { as: 'div', width: '260', children: _jsx(Text, { variant: "bodyMd", as: "p", children: "Priority support" }) })] }))] }), !currentPlan && (_jsx(Button, { onClick: purchase, variant: 'secondary', children: "Subscribe" }))] }) }) }));
};
export default PlanCard;
