import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import { Button } from '@shopify/polaris';
import { useSerialize } from '../../editor/context.tsx';
import { useSaveTemplateRequest } from '../../hooks/useSaveTemplateRequest.ts';
function TestTemplateButton({ id, name, error, ok, }) {
    const serialize = useSerialize();
    const saveTemplate = useSaveTemplateRequest();
    const saveAndClose = useCallback(() => {
        let templateData = serialize().drawItems;
        if (!id && templateData.length === 0)
            return error('Template is empty');
        saveTemplate(id, name, templateData)
            .then((answer) => {
            ok(answer._id);
        })
            .catch(function (err) {
            error(err.message);
        });
    }, [saveTemplate, serialize]);
    return (_jsx(Button, { fullWidth: true, onClick: saveAndClose, children: "Test template" }));
}
export default TestTemplateButton;
