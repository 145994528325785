import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useState } from 'react';
import { BorderStyleEnum, } from "../../../../types";
import { PropField } from "../Shared/PropField";
import { ColorPicker } from "../Shared/ColorPicker";
import { NumberInput } from "../Shared/NumberInput";
import { DropDown } from "../Shared/DropDown";
import { SliderComponent } from "../Shared/Slider";
import { SelectDropdown } from "../Shared/SelectDropdown";
import './CircleProps.scss';
export const CircleProps = function CircleProps({ item, modifyItemProperty }) {
    const { id, size, opacity, color, shadowColor, shadowBlur, shadowSpread, shadowHorizontalOffset, shadowVerticalOffset, shadowOpacity, borderWidth, borderRadius, borderColor, borderStyle, } = item;
    const [openedTab, setOpenedTab] = useState('circle');
    const onChange = useCallback((propName, value) => {
        if (propName === 'borderStyle')
            setLocalBorderStyle(value);
        modifyItemProperty(id, propName, value);
        if (['borderStyle', 'borderRadius', 'borderColor', 'shadowOpacity'].includes(propName) && borderWidth === 0) {
            modifyItemProperty(id, 'borderWidth', 1);
        }
    }, [id, borderWidth]);
    const onPreview = (propName, value) => {
        modifyItemProperty(id, propName, value);
    };
    const [localBorderStyle, setLocalBorderStyle] = useState(borderStyle);
    useEffect(function () {
        setLocalBorderStyle(borderStyle);
    }, [id]);
    return _jsxs("div", { className: 'editor-component-props-panel-circle-props-block', children: [_jsx(DropDown, { title: 'Circle props', isOpen: openedTab === 'circle', onClick: () => setOpenedTab(openedTab === 'circle' ? 'none' : 'circle') }), _jsxs("div", { className: 'editor-component-props-panel-dropdown-content-container', style: { display: openedTab === 'circle' ? 'block' : 'none' }, children: [_jsx(PropField, { title: 'Color:', children: _jsx(ColorPicker, { value: color, propName: 'color', onChange: onChange }) }), _jsx(PropField, { title: 'Opacity:', children: _jsx(SliderComponent, { propName: 'opacity', value: opacity, min: 0, max: 1., step: 0.01, 
                            // @ts-ignore
                            onChange: onChange }) })] }), _jsx(DropDown, { title: 'Shadow', isOpen: openedTab === 'shadow', onClick: () => setOpenedTab(openedTab === 'shadow' ? 'none' : 'shadow') }), _jsxs("div", { className: 'editor-component-props-panel-dropdown-content-container', style: { display: openedTab === 'shadow' ? 'block' : 'none' }, children: [_jsx(PropField, { title: 'Color:', children: _jsx(ColorPicker, { value: shadowColor, propName: 'shadowColor', onChange: onChange }) }), _jsx(PropField, { title: 'Radius:', children: _jsx(NumberInput, { propName: 'shadowSpread', value: shadowSpread, min: 0, max: 999, onChange: onChange }) }), _jsx(PropField, { title: 'Blur:', children: _jsx(NumberInput, { propName: 'shadowBlur', value: shadowBlur, min: 0, max: 999, onChange: onChange }) }), _jsx(PropField, { title: 'Offset x', children: _jsx(NumberInput, { propName: 'shadowHorizontalOffset', value: shadowHorizontalOffset, min: -999, max: 999, onChange: onChange }) }), _jsx(PropField, { title: 'Offset y:', children: _jsx(NumberInput, { propName: 'shadowVerticalOffset', value: shadowVerticalOffset, min: -999, max: 999, onChange: onChange }) }), _jsx(PropField, { title: 'Opacity:', children: _jsx(SliderComponent, { propName: 'shadowOpacity', value: shadowOpacity, min: 0, max: 1., step: 0.01, onChange: onChange }) })] }), _jsx(DropDown, { title: 'Border', isOpen: openedTab === 'border', onClick: () => setOpenedTab(openedTab === 'border' ? 'none' : 'border') }), _jsxs("div", { className: 'editor-component-props-panel-dropdown-content-container', style: { display: openedTab === 'border' ? 'block' : 'none' }, children: [_jsx(PropField, { title: 'Width:', children: _jsx(NumberInput, { propName: 'borderWidth', value: borderWidth, min: 0, max: 999, onChange: onChange }) }), _jsx(PropField, { title: 'Style:', children: _jsx(SelectDropdown, { propName: 'borderStyle', value: localBorderStyle, withSearch: false, maxHeight: 1000, 
                            // @ts-ignore
                            values: Object.keys(BorderStyleEnum).map(a => ({ value: BorderStyleEnum[a], label: BorderStyleEnum[a] })), onChange: onChange, onPreview: onPreview }) }), _jsx(PropField, { title: 'Color:', children: _jsx(ColorPicker, { value: borderColor, propName: 'borderColor', onChange: onChange }) })] })] });
};
