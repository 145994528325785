import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useDeserialize } from "../../editor/context.tsx";
function Deserialize({ state }) {
    const deserialize = useDeserialize();
    useEffect(function () {
        if (state.length > 0) {
            deserialize(state);
        }
    }, [state]);
    return _jsx("div", {});
}
export default Deserialize;
