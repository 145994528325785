import useSWR from 'swr';
import useFetch from './useFetch';
import { useStore } from '../stores';
export const useTemplate = function (id) {
    if (!id) {
        const storeTemplate = useStore((state) => state.edit_template);
        return [storeTemplate, false, undefined];
    }
    const fetcher = useFetch();
    const { data, error, isLoading } = useSWR(`/api/user/template/${id}`, fetcher, {
        revalidateIfStale: true,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        errorRetryInterval: 5000,
        errorRetryCount: 100,
        refreshInterval: 0,
    });
    return [data, error, isLoading];
};
