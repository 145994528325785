export var SubscriptionPlanEnum;
(function (SubscriptionPlanEnum) {
    SubscriptionPlanEnum["None"] = "None";
    SubscriptionPlanEnum["Trial"] = "Trial";
    SubscriptionPlanEnum["Basic"] = "Basic";
    SubscriptionPlanEnum["Business"] = "Business";
    SubscriptionPlanEnum["Enterprise"] = "Enterprise";
})(SubscriptionPlanEnum || (SubscriptionPlanEnum = {}));
export var SubscriptionPrices;
(function (SubscriptionPrices) {
    SubscriptionPrices[SubscriptionPrices["None"] = 0] = "None";
    SubscriptionPrices[SubscriptionPrices["Trial"] = 0] = "Trial";
    SubscriptionPrices[SubscriptionPrices["Basic"] = 9.99] = "Basic";
    SubscriptionPrices[SubscriptionPrices["Business"] = 49.99] = "Business";
    SubscriptionPrices[SubscriptionPrices["Enterprise"] = 999.99] = "Enterprise";
})(SubscriptionPrices || (SubscriptionPrices = {}));
export var RenderCountsForPlan;
(function (RenderCountsForPlan) {
    RenderCountsForPlan[RenderCountsForPlan["None"] = 0] = "None";
    RenderCountsForPlan[RenderCountsForPlan["Trial"] = 1000] = "Trial";
    RenderCountsForPlan[RenderCountsForPlan["Basic"] = 1000] = "Basic";
    RenderCountsForPlan[RenderCountsForPlan["Business"] = 20000] = "Business";
    RenderCountsForPlan[RenderCountsForPlan["Enterprise"] = 100000] = "Enterprise";
})(RenderCountsForPlan || (RenderCountsForPlan = {}));
export var TemplatesCountsForPlan;
(function (TemplatesCountsForPlan) {
    TemplatesCountsForPlan[TemplatesCountsForPlan["None"] = 0] = "None";
    TemplatesCountsForPlan[TemplatesCountsForPlan["Trial"] = 3] = "Trial";
    TemplatesCountsForPlan[TemplatesCountsForPlan["Basic"] = 3] = "Basic";
    TemplatesCountsForPlan[TemplatesCountsForPlan["Business"] = 20] = "Business";
    TemplatesCountsForPlan[TemplatesCountsForPlan["Enterprise"] = 500] = "Enterprise";
})(TemplatesCountsForPlan || (TemplatesCountsForPlan = {}));
export var UserPlanToS3ErrorRequestPerMonthCount;
(function (UserPlanToS3ErrorRequestPerMonthCount) {
    UserPlanToS3ErrorRequestPerMonthCount["Basic"] = "1_000_000";
    UserPlanToS3ErrorRequestPerMonthCount["Business"] = "20_000_000";
    UserPlanToS3ErrorRequestPerMonthCount["Enterprise"] = "100_000_000";
})(UserPlanToS3ErrorRequestPerMonthCount || (UserPlanToS3ErrorRequestPerMonthCount = {}));
