import { jsx as _jsx } from "react/jsx-runtime";
import '@shopify/polaris/build/esm/styles.css';
import { AppProvider as PolarisProvider } from '@shopify/polaris';
import { useRoutes } from 'raviger';
import routes from './Routes';
import UserProvider from './providers/UserProvider.tsx';
import { useI18n } from '@shopify/react-i18n';
import en from './translations/en.json';
const App = () => {
    const RouteComponents = useRoutes(routes);
    const [i18n, ShareTranslations] = useI18n({
        id: 'Translations',
        translations: {
            en: en,
        },
    });
    return (_jsx(PolarisProvider, { i18n: i18n.translations, children: _jsx(ShareTranslations, { children: _jsx(UserProvider, { children: RouteComponents }) }) }));
};
export default App;
