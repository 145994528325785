import WebFont from "./webfontloader";
export var FontActionTypes;
(function (FontActionTypes) {
    FontActionTypes["Load"] = "LOAD_FONT";
    FontActionTypes["Loaded"] = "FONT_LOADED";
})(FontActionTypes || (FontActionTypes = {}));
export const fontReducer = (state, action) => {
    switch (action.type) {
        case FontActionTypes.Load:
            return state.map(font => {
                if (font.fontFamily === action.payload.fontFamily && font.weights.includes(action.payload.weight) && !font.loadedWeights.includes(action.payload.weight)) {
                    // @ts-ignore
                    if (typeof window !== 'undefined') {
                        WebFont.load({
                            google: {
                                families: [`${font.link}:${font.italic ? 'i' : ''}${action.payload.weight}`]
                            },
                            fontloading: action.payload.asyncOnLoad
                        });
                        return {
                            ...font,
                            inLoading: font.inLoading + 1,
                            loadedWeights: [...font.loadedWeights, action.payload.weight]
                        };
                    }
                    else {
                        return font;
                    }
                }
                else {
                    return font;
                }
            });
        case FontActionTypes.Loaded:
            return state.map(font => {
                if (font.fontFamily === action.payload.fontFamily) {
                    return {
                        ...font,
                        inLoading: font.inLoading - 1,
                    };
                }
                else {
                    return font;
                }
            });
        default:
            return state;
    }
};
