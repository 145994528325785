import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useRef, useState } from 'react';
import { ActionList, Button, DropZone, Popover, Spinner } from '@shopify/polaris';
import { UploadIcon } from '@shopify/polaris-icons';
import { useDrawItemsWithActions } from '../../editor/context';
import { getInitialDataForItemType, getSizeForText, } from '../../editor/components/ComponentsPanel/components/AddComponentButton';
import { DrawTypeEnum } from '../../editor/types';
import { useI18n } from '@shopify/react-i18n';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { useImageFileUploader } from '../../hooks/useImageFileUploader.tsx';
function TemplateAddComponentButton({ errorModalCallback }) {
    const [i18n] = useI18n();
    const [popoverActive, setPopoverActive] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSelectImagePopupActive, setIsSelectImagePopupActive] = useState(false);
    const imageUploadButtonRef = useRef(null);
    const { addNewItem } = useDrawItemsWithActions();
    const imageFileUploader = useImageFileUploader();
    const uploadImage = useCallback(function (file) {
        console.log('upload file', file);
        setIsLoading(true);
        imageFileUploader(file)
            .then((link) => {
            addNewItem({
                ...getInitialDataForItemType(DrawTypeEnum.Image),
                link: link,
            });
        })
            .catch((err) => {
            //TODO error handling
            console.log(err);
            errorModalCallback(err.message);
            setIsLoading(false);
        })
            .then(() => {
            setIsSelectImagePopupActive(false);
            setIsLoading(false);
        });
    }, [setIsSelectImagePopupActive, imageFileUploader, addNewItem]);
    // const imageUploadButtonRef = useRef(null);
    const onAddNewItem = useCallback((item_type, isTemplated = false) => {
        switch (item_type) {
            case DrawTypeEnum.Text:
                setPopoverActive(false);
                let text = 'You can change this text at render time and in right panel';
                addNewItem({
                    ...getInitialDataForItemType(item_type),
                    text: text,
                    color: '#ff0000',
                    size: getSizeForText(text),
                });
                break;
            case DrawTypeEnum.Image:
                setPopoverActive(false);
                setIsSelectImagePopupActive(true);
                break;
            case DrawTypeEnum.Rect:
                setPopoverActive(false);
                addNewItem({
                    ...getInitialDataForItemType(item_type),
                });
                break;
            case DrawTypeEnum.Circle:
                setPopoverActive(false);
                addNewItem({
                    ...getInitialDataForItemType(item_type),
                });
                break;
        }
    }, [addNewItem]);
    const activator = (_jsx("div", { style: {
            minWidth: '180px',
            height: '36px',
            verticalAlign: 'center',
            display: 'flex',
        }, children: _jsx(Button, { fullWidth: true, onClick: () => setPopoverActive(true), disclosure: true, children: "Add component" }) }));
    const handleDrop = useCallback((_droppedFiles, acceptedFiles, rejectedFiles) => {
        uploadImage(acceptedFiles[0]);
    }, []);
    return (_jsxs("div", { children: [_jsx(Popup, { open: isSelectImagePopupActive, onClose: () => setIsSelectImagePopupActive(false), children: _jsx("div", { children: _jsx("div", { className: 'app-editor-page-upload-image-container', children: _jsx(DropZone, { accept: "image/*", type: "image", label: 'Please upload placeholder image. You will able to change this image to another on render time by url param.', variableHeight: false, allowMultiple: false, onDrop: handleDrop, children: _jsx("div", { style: {
                                    position: 'absolute',
                                    left: '0',
                                    right: '0',
                                    top: '0',
                                    bottom: '0',
                                }, children: _jsx("div", { className: 'app-editor-page-upload-image-button-icon', children: isLoading ? _jsx(Spinner, {}) : _jsx(UploadIcon, { fill: 'grey' }) }) }) }) }) }) }), _jsx(Popover, { active: popoverActive, fullWidth: true, activator: activator, onClose: () => setPopoverActive(false), children: _jsx(ActionList, { actionRole: "menuitem", items: [
                        {
                            content: 'Add text',
                            onAction: () => {
                                onAddNewItem(DrawTypeEnum.Text);
                            },
                        },
                        {
                            content: 'Add image',
                            onAction: () => {
                                onAddNewItem(DrawTypeEnum.Image);
                            },
                        },
                        {
                            content: 'Rect',
                            onAction: () => {
                                onAddNewItem(DrawTypeEnum.Rect);
                            },
                        },
                        {
                            content: 'Circle',
                            onAction: () => {
                                onAddNewItem(DrawTypeEnum.Circle);
                            },
                        },
                    ] }) })] }));
}
export default TemplateAddComponentButton;
