import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import "./Slider.scss";
import Slider, { SliderTooltip, Handle } from 'rc-slider';
const handle = (props) => {
    const { value, dragging, index, ...restProps } = props;
    return (_jsx(SliderTooltip, { prefixCls: "rc-slider-tooltip", overlay: `${value}`, visible: dragging, placement: "bottom", children: _jsx(Handle, { value: value, ...restProps }) }, index));
};
export const SliderComponent = function SliderComponent({ propName, value, step, min, max, onChange }) {
    return _jsx("div", { className: 'canva-props-panel-slider-root', children: _jsx(Slider, { step: step, min: min, max: max, value: value, onChange: (value) => onChange(propName, value), handle: handle }) });
};
