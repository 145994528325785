import { jsx as _jsx } from "react/jsx-runtime";
import { Fragment, useEffect } from 'react';
import { Layout, Page, Spinner } from '@shopify/polaris';
import useSWR from 'swr';
import useFetch from '../hooks/useFetch.ts';
import { useStore } from '../stores';
function UserProvider({ children }) {
    const setUser = useStore((state) => state.setUser);
    const userId = useStore((state) => state.user._id);
    const fetcher = useFetch();
    const { data, error, isLoading } = useSWR('/api/user', fetcher, {
        refreshInterval: 0,
        revalidateIfStale: true,
        revalidateOnMount: true,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        loadingTimeout: 60000,
        shouldRetryOnError: true,
        errorRetryInterval: 5000,
        errorRetryCount: 1000,
    });
    useEffect(function () {
        if (data) {
            setUser(data);
        }
    }, [data, error, isLoading, setUser]);
    if (!userId) {
        return (_jsx(Page, { fullWidth: true, children: _jsx(Layout, { children: _jsx(Layout.Section, { variant: 'fullWidth', children: _jsx("div", { style: { textAlign: 'center', marginTop: '50px' }, children: _jsx(Spinner, {}) }) }) }) }));
    }
    return _jsx(Fragment, { children: children });
}
export default UserProvider;
