import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @ts-nocheck
//this dependency modified, original files on github.com/fraserxu/react-dropdown
import React, { Component, createRef } from 'react';
import classNames from 'classnames';
const DEFAULT_PLACEHOLDER_STRING = 'Select...';
class Dropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: this.parseValue(props.value, props.options) || {
                label: typeof props.placeholder === 'undefined' ? DEFAULT_PLACEHOLDER_STRING : props.placeholder,
                value: '',
            },
            search: '',
            isOpen: false
        };
        this.state.search = this.state.selected.value;
        this.dropdownRef = createRef();
        this.mounted = true;
        this.handleDocumentClick = this.handleDocumentClick.bind(this);
        this.fireChangeEvent = this.fireChangeEvent.bind(this);
    }
    componentDidUpdate(prevProps) {
        if (this.props.value !== prevProps.value) {
            if (this.props.value) {
                this.setState({ search: this.props.value });
                let selected = this.parseValue(this.props.value, this.props.options);
                if (selected !== this.state.selected) {
                    this.setState({ selected });
                }
            }
            else {
                this.setState({ search: typeof this.props.placeholder === 'undefined' ? '' : this.props.placeholder });
                this.setState({
                    selected: {
                        label: typeof this.props.placeholder === 'undefined' ? DEFAULT_PLACEHOLDER_STRING : this.props.placeholder,
                        value: ''
                    }
                });
            }
        }
    }
    componentDidMount() {
        document.addEventListener('click', this.handleDocumentClick, false);
        document.addEventListener('touchend', this.handleDocumentClick, false);
    }
    componentWillUnmount() {
        this.mounted = false;
        document.removeEventListener('click', this.handleDocumentClick, false);
        document.removeEventListener('touchend', this.handleDocumentClick, false);
    }
    handleMouseDown(event) {
        if (this.props.onFocus && typeof this.props.onFocus === 'function') {
            this.props.onFocus(this.state.isOpen);
        }
        if (event.type === 'mousedown' && event.button !== 0)
            return;
        event.stopPropagation();
        event.preventDefault();
        if (!this.props.disabled) {
            this.setState({
                isOpen: !this.state.isOpen
            });
        }
    }
    parseValue(value, options) {
        let option;
        if (typeof value === 'string') {
            for (let i = 0, num = options.length; i < num; i++) {
                if (options[i].type === 'group') {
                    const match = options[i].items.filter(item => item.value === value);
                    if (match.length) {
                        option = match[0];
                    }
                }
                else if (typeof options[i].value !== 'undefined' && options[i].value === value) {
                    option = options[i];
                }
            }
        }
        return option || value;
    }
    setValue(value, label) {
        let newState = {
            selected: {
                value,
                label
            },
            isOpen: false
        };
        this.fireChangeEvent(newState);
        this.setState(newState);
    }
    fireChangeEvent(newState) {
        if (newState.selected !== this.state.selected && this.props.onChange) {
            this.props.onChange(newState.selected);
        }
    }
    fireHoverStartEvent(state) {
        this.props.onHoverStart && this.props.onHoverStart(state);
    }
    fireHoverEndEvent(state) {
        this.props.onHoverEnd && this.props.onHoverEnd(this.state.selected);
    }
    renderOption(option) {
        let value = option.value;
        if (typeof value === 'undefined') {
            value = option.label || option;
        }
        let label = option.label || option.value || option;
        let isSelected = value === this.state.selected.value || value === this.state.selected;
        const classes = {
            [`${this.props.baseClassName}-option`]: true,
            [option.className]: !!option.className,
            'is-selected': isSelected
        };
        const optionClass = classNames(classes);
        return (_jsx("div", { style: { display: this.state.search.toLowerCase() === this.state.selected.value.toLowerCase() || value.toLowerCase().includes(this.state.search.trim().toLowerCase()) ? 'block' : 'none' }, className: optionClass, onMouseDown: this.setValue.bind(this, value, label), onMouseOver: this.fireHoverStartEvent.bind(this, { value, label }), onMouseOut: this.fireHoverEndEvent.bind(this, { value, label }), onClick: this.setValue.bind(this, value, label), role: 'option', "aria-selected": isSelected ? 'true' : 'false', children: label }, value));
    }
    buildMenu() {
        let { options, baseClassName } = this.props;
        let ops = options.map((option) => {
            if (option.type === 'group') {
                let groupTitle = (_jsx("div", { className: `${baseClassName}-title`, children: option.name }));
                let _options = option.items.map((item) => this.renderOption(item));
                return (_jsxs("div", { className: `${baseClassName}-group`, role: 'listbox', tabIndex: '-1', children: [groupTitle, _options] }, option.name));
            }
            else {
                return this.renderOption(option);
            }
        });
        return ops.length ? ops : _jsx("div", { className: `${baseClassName}-noresults`, children: "No options found" });
    }
    handleDocumentClick(event) {
        if (this.mounted) {
            if (!this.dropdownRef.current.contains(event.target)) {
                if (this.state.isOpen) {
                    this.setState({ isOpen: false });
                }
            }
        }
    }
    isValueSelected() {
        return typeof this.state.selected === 'string' || this.state.selected.value !== '';
    }
    render() {
        const { baseClassName, controlClassName, placeholderClassName, menuClassName, arrowClassName, arrowClosed, arrowOpen, className } = this.props;
        const disabledClass = this.props.disabled ? 'Dropdown-disabled' : '';
        const placeHolderValue = typeof this.state.selected === 'string' ? this.state.selected : this.state.selected.label;
        const dropdownClass = classNames({
            [`${baseClassName}-root`]: true,
            [className]: !!className,
            'is-open': this.state.isOpen
        });
        const controlClass = classNames({
            [`${baseClassName}-control`]: true,
            [controlClassName]: !!controlClassName,
            [disabledClass]: !!disabledClass
        });
        const placeholderClass = classNames({
            [`${baseClassName}-placeholder`]: true,
            [placeholderClassName]: !!placeholderClassName,
            'is-selected': this.isValueSelected()
        });
        const menuClass = classNames({
            [`${baseClassName}-menu`]: true,
            [menuClassName]: !!menuClassName
        });
        const arrowClass = classNames({
            [`${baseClassName}-arrow`]: true,
            [arrowClassName]: !!arrowClassName
        });
        const value = (_jsx("div", { className: placeholderClass, children: placeHolderValue }));
        const menu = this.state.isOpen ? _jsx("div", { className: menuClass, "aria-expanded": 'true', style: { maxHeight: `${this.props.maxHeight}px`, overflowY: 'scroll' }, children: this.buildMenu() }) : null;
        return (_jsxs("div", { ref: this.dropdownRef, className: dropdownClass, children: [_jsxs("div", { className: controlClass, onClick: this.handleMouseDown.bind(this), "aria-haspopup": 'listbox', children: [_jsx("input", { readOnly: !this.props.withSearch, type: this.props.inputType || 'text', style: { padding: '0 10px', height: '38px' }, value: this.state.search, onChange: (e) => this.setState({ search: e.target.value }) }), _jsx("div", { className: `${baseClassName}-arrow-wrapper`, children: arrowOpen && arrowClosed
                                ? this.state.isOpen ? arrowOpen : arrowClosed
                                : _jsx("span", { className: arrowClass }) })] }), menu] }));
    }
}
Dropdown.defaultProps = { baseClassName: 'Dropdown' };
export default Dropdown;
