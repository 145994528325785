import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useRef, useState } from 'react';
import './ColorPicker.scss';
import { HexColorPicker } from 'react-colorful';
function validateColor(str) {
    if (str[0] !== '#') {
        str = '#' + str;
    }
    // @ts-ignore
    str = '#' + str.slice(1, 7).replaceAll(/[^0-9a-fA-F]/g, '');
    return str;
}
export const ColorPicker = function ColorPicker({ value, propName, onChange }) {
    let [pickerOpened, setPickerOpened] = useState({
        lastPickerClick: 0,
        visible: false,
    });
    let inputRef = useRef(null);
    return (_jsxs("div", { className: 'canva-props-panel-color-picker-root', children: [_jsx("input", { ref: inputRef, value: value, spellCheck: false, onFocus: () => setPickerOpened({
                    lastPickerClick: 0,
                    visible: true,
                }), onBlur: () => setPickerOpened((prevstate) => {
                    if (prevstate.lastPickerClick > Date.now() - 50) {
                        // @ts-ignore
                        inputRef.current.focus();
                    }
                    return {
                        lastPickerClick: Date.now(),
                        visible: prevstate.lastPickerClick > Date.now() - 50,
                    };
                }), onChange: (e) => {
                    onChange(propName, validateColor(e.target.value));
                } }), _jsx("div", { style: { backgroundColor: value }, className: 'canva-props-panel-color-picker-preview' }), _jsx("div", { className: 'canva-props-panel-color-picker-container', children: pickerOpened.visible && (_jsx(HexColorPicker, { color: value, onMouseDown: () => setPickerOpened(() => {
                        // @ts-ignore
                        inputRef.current.focus();
                        return {
                            lastPickerClick: Date.now(),
                            visible: true,
                        };
                    }), onChange: (c) => onChange(propName, c) })) })] }));
};
