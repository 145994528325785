import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BlockStack, Button, Card, Layout, Page, Scrollable, Spinner, Text, } from '@shopify/polaris';
import { useStore } from '../stores';
import CrispSpacer from '../components/CrispSpacer.tsx';
import { navigate } from 'raviger';
import { useTemplates } from '../hooks/useTemplates.tsx';
import TemplateCard from '../components/Cards/TemplateCard.tsx';
import { RenderCountsForPlan } from '../types';
const HomePage = () => {
    const userInfo = useStore((state) => state.user);
    const setEditTemplate = useStore((state) => state.setEditTemplate);
    const [templates, templatesLoadingError, isTemplatesLoading] = useTemplates();
    // const [products, loadMoreProducts, isProductsLoading] = useProducts();
    // const [setError, ErrorModal] = useErrorHelper();
    return (_jsx("div", { style: {
            height: '100%',
            width: '100%',
        }, children: _jsx(Scrollable, { focusable: false, style: {
                width: '100%',
                height: '100%',
            }, onScrolledToBottom: () => {
                // nothing by now we fetch all templates
                // loadMoreTemplates();
            }, children: _jsxs(Page, { title: 'Templates', primaryAction: {
                    content: 'Create template',
                    disabled: false,
                    onAction() {
                        setEditTemplate({
                            _id: null,
                            name: 'Test ' + Math.floor(Math.random() * 1000000),
                            template_json: {
                                width: 1200,
                                height: 628,
                                state: [],
                            },
                        });
                        navigate('/select_template');
                    },
                }, secondaryActions: _jsx(Button, { onClick: () => navigate('/subscription'), children: "Subscription" }), children: [isTemplatesLoading && !templatesLoadingError && _jsx(Spinner, {}), templatesLoadingError && (_jsx(Text, { variant: 'headingMd', tone: 'critical', as: 'p', children: "Templates loading error!" })), _jsxs(BlockStack, { children: [_jsx(Layout, { children: _jsxs(Layout.Section, { children: [_jsxs(Text, { variant: "bodyMd", as: "p", children: ["You on ", userInfo.on_plan, " plan"] }), _jsx(Text, { variant: "bodyMd", as: "p", children: templates &&
                                                userInfo.usage.current !== null &&
                                                `Current month renders: ${userInfo.usage.current}/${userInfo.override_soft_limit || RenderCountsForPlan[userInfo.on_plan]}` }), _jsx(Text, { variant: "bodyMd", as: "p", children: userInfo.usage.current !== null &&
                                                userInfo.usage.prev !== null &&
                                                `Previous month renders: ${userInfo.usage.prev}/${userInfo.override_soft_limit || RenderCountsForPlan[userInfo.on_plan]}` })] }) }), _jsxs("div", { style: {
                                    maxWidth: '760px',
                                    width: '100%',
                                    margin: '20px auto 0 auto',
                                }, children: [templates && templates.length > 0 && (_jsx(Text, { as: 'p', variant: 'headingMd', children: "Templates:" })), templates && templates.length === 0 && (_jsxs(Card, { children: [_jsx(Text, { as: 'p', variant: 'headingLg', children: "Welcome to preview builder" }), _jsx("br", {}), _jsx(Text, { as: 'p', variant: 'headingMd', children: "In our app you can setup templates to generate dynamic images to automate your marketing and increase number of leads and brand recognition with rich previews of links in social media and messengers." }), _jsx("br", {}), _jsx(Button, { variant: 'primary', onClick: () => navigate('/select_template'), children: "Create first template" })] }))] }), !isTemplatesLoading &&
                                !templatesLoadingError &&
                                Array.isArray(templates) &&
                                templates.map((t) => (_jsx("div", { style: { maxWidth: '640px', margin: '20px auto' }, children: _jsx(TemplateCard, { data: t, onEditTemplate: () => {
                                            navigate(`/edit_template/${t._id}`);
                                            console.log('On edit');
                                        }, onTest: () => {
                                            navigate(`/integrate_template/${t._id}`);
                                        } }) }, t._id))), _jsx(CrispSpacer, {})] })] }) }) }));
};
export default HomePage;
