import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import "./FileInput.scss";
import { useImageUploadCallback, useErrorModalCallback } from "../../../../../../context";
export const FileInput = function FileInput({ title, propName, url, onChange }) {
    const imageUploadCallback = useImageUploadCallback();
    const errorModalCallback = useErrorModalCallback();
    return _jsxs("div", { className: "canva-props-panel-file-input-root", children: [_jsx("label", { htmlFor: "upload-image-button", children: "Click to change image!" }), _jsx("input", { id: "upload-image-button", type: 'file', style: { display: 'none' }, onChange: (e) => {
                    // @ts-ignore
                    if (e.target.files.length && e.target.files[0]) {
                        // @ts-ignore
                        imageUploadCallback(e.target.files[0]).then((id) => {
                            onChange(propName, id);
                        }).catch((err) => {
                            console.log(err);
                            errorModalCallback(err.message);
                            return Promise.resolve();
                        });
                    }
                } })] });
};
