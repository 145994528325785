import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import './PropsPanel.scss';
import { useDrawItemsWithActions, useSelectedItemWithActions } from "../../context";
import { DrawTypeEnum } from "../../types";
import { TextProps } from "./components/TextProps";
import { unreachable } from "../../utils";
import { ImageProps } from "./components/ImageProps";
import { RectProps } from "./components/RectProps";
import { CircleProps } from "./components/CircleProps";
import { SelectedItemMenu } from "./components/SelectedItemMenu";
function getComponentForSelectedItem(item, modifyItemProperty) {
    if (item.type === DrawTypeEnum.Text) {
        return _jsx(TextProps, { item: item, modifyItemProperty: modifyItemProperty });
    }
    else if (item.type === DrawTypeEnum.Image) {
        return _jsx(ImageProps, { item: item, modifyItemProperty: modifyItemProperty });
    }
    else if (item.type === DrawTypeEnum.Rect) {
        return _jsx(RectProps, { item: item, modifyItemProperty: modifyItemProperty });
    }
    else if (item.type === DrawTypeEnum.Circle) {
        return _jsx(CircleProps, { item: item, modifyItemProperty: modifyItemProperty });
    }
    else {
        unreachable(item);
    }
}
export const PropsPanel = function PropsPanel({ direction }) {
    //on first mount calculate the ideal canva scale for client browser window size.
    const { selectedItem, modifyItemProperty } = useSelectedItemWithActions();
    const { drawItems, moveItemInLayers, addNewItem, deleteItem } = useDrawItemsWithActions();
    let component;
    if (selectedItem) {
        component = getComponentForSelectedItem(selectedItem, modifyItemProperty);
    }
    else {
        component = _jsx("div", {});
    }
    return _jsxs("div", { className: 'editor-component-props-panel-root', children: [selectedItem && _jsx(SelectedItemMenu, { moveLayerUp: () => { moveItemInLayers(selectedItem.id, 'up'); }, moveLayerDown: () => { moveItemInLayers(selectedItem.id, 'down'); }, canMoveLayerUp: drawItems.indexOf(selectedItem) !== drawItems.length - 1, canMoveLayerDown: drawItems.indexOf(selectedItem) !== 0, duplicate: () => {
                    addNewItem({ ...selectedItem, position: {
                            x: selectedItem.position.x + 50 - 100 * Math.random(),
                            y: selectedItem.position.y + 50 - 100 * Math.random(),
                        } });
                }, remove: () => { deleteItem(selectedItem.id); } }), _jsx("div", { className: 'editor-component-props-panel-container', children: component })] });
};
