import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import './Initial.scss';
import { I18nContext, I18nManager } from '@shopify/react-i18n';
const locale = 'en';
const i18nManager = new I18nManager({
    locale,
    onError(error) {
        console.log('localization error', error);
    },
});
ReactDOM.createRoot(document.getElementById('root')).render(_jsx(React.StrictMode, { children: _jsx(I18nContext.Provider, { value: i18nManager, children: _jsx(App, {}) }) }));
// @ts-ignore
if (module && module.hot && module.hot.accept) {
    // @ts-ignore
    module.hot.accept();
}
