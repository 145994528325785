import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useState } from 'react';
import { BorderStyleEnum, ImageSizeMode } from '../../../../types';
import { SelectDropdown } from '../Shared/SelectDropdown';
import { PropField } from '../Shared/PropField';
import { ColorPicker } from '../Shared/ColorPicker';
import './ImageProps.scss';
import { NumberInput } from '../Shared/NumberInput';
import { DropDown } from '../Shared/DropDown';
import { SliderComponent } from '../Shared/Slider';
import { FileInput } from './components/FileInput';
import { TextInput } from '../Shared/TextInput';
export const ImageProps = function ImageProps({ item, modifyItemProperty }) {
    const { id, link, position, size, rotationAngle, opacity, imageSizeMode, shadowColor, shadowBlur, shadowSpread, shadowHorizontalOffset, shadowVerticalOffset, shadowOpacity, borderWidth, borderRadius, borderColor, borderStyle, } = item;
    const [openedTab, setOpenedTab] = useState('image');
    const onChange = useCallback((propName, value) => {
        if (propName === 'borderStyle')
            setLocalBorderStyle(value);
        if (propName === 'imageSizeMode')
            setLocalImageSizeMode(value);
        modifyItemProperty(id, propName, value);
        if ([
            'borderStyle',
            'borderRadius',
            'borderColor',
            'shadowOpacity',
        ].includes(propName) &&
            borderWidth === 0) {
            modifyItemProperty(id, 'borderWidth', 1);
        }
    }, [id, borderWidth]);
    const onPreview = (propName, value) => {
        modifyItemProperty(id, propName, value);
    };
    const [localBorderStyle, setLocalBorderStyle] = useState(borderStyle);
    const [localImageSizeMode, setLocalImageSizeMode] = useState(imageSizeMode);
    useEffect(function () {
        setLocalBorderStyle(borderStyle);
        setLocalImageSizeMode(imageSizeMode);
    }, [id]);
    return (_jsxs("div", { className: 'editor-component-props-panel-image-props-block', children: [_jsx(DropDown, { title: 'Image props', isOpen: openedTab === 'image', onClick: () => setOpenedTab(openedTab === 'image' ? 'none' : 'image') }), _jsxs("div", { className: 'editor-component-props-panel-dropdown-content-container', style: { display: openedTab === 'image' ? 'block' : 'none' }, children: [_jsx(PropField, { title: 'Variable name to change on render time:', children: _jsx(TextInput, { isTextAread: false, propName: 'id', value: 'image' + id, disabled: true, onChange: onChange }) }), _jsx(PropField, { title: 'Uploaded image url:', children: _jsx(TextInput, { isTextAread: true, propName: 'link', value: link, disabled: true, onChange: onChange }) }), _jsx(PropField, { title: 'Image:', children: _jsx(FileInput, { title: 'Click to upload image', url: `/api/upload`, propName: 'link', onChange: onChange }) }), _jsx(PropField, { title: 'Size mode:', children: _jsx(SelectDropdown, { propName: 'imageSizeMode', value: localImageSizeMode, maxHeight: 1000, withSearch: false, 
                            // @ts-ignore
                            values: Object.keys(ImageSizeMode).map((a) => ({
                                value: ImageSizeMode[a],
                                label: ImageSizeMode[a],
                            })), onChange: onChange, onPreview: onPreview }) }), _jsx(PropField, { title: 'Opacity:', children: _jsx(SliderComponent, { propName: 'opacity', value: opacity, min: 0, max: 1, step: 0.01, 
                            // @ts-ignore
                            onChange: onChange }) })] }), _jsx(DropDown, { title: 'Shadow', isOpen: openedTab === 'shadow', onClick: () => setOpenedTab(openedTab === 'shadow' ? 'none' : 'shadow') }), _jsxs("div", { className: 'editor-component-props-panel-dropdown-content-container', style: { display: openedTab === 'shadow' ? 'block' : 'none' }, children: [_jsx(PropField, { title: 'Color:', children: _jsx(ColorPicker, { value: shadowColor, propName: 'shadowColor', onChange: onChange }) }), _jsx(PropField, { title: 'Radius:', children: _jsx(NumberInput, { propName: 'shadowSpread', value: shadowSpread, min: 0, max: 999, onChange: onChange }) }), _jsx(PropField, { title: 'Blur:', children: _jsx(NumberInput, { propName: 'shadowBlur', value: shadowBlur, min: 0, max: 999, onChange: onChange }) }), _jsx(PropField, { title: 'Offset x', children: _jsx(NumberInput, { propName: 'shadowHorizontalOffset', value: shadowHorizontalOffset, min: -999, max: 999, onChange: onChange }) }), _jsx(PropField, { title: 'Offset y:', children: _jsx(NumberInput, { propName: 'shadowVerticalOffset', value: shadowVerticalOffset, min: -999, max: 999, onChange: onChange }) }), _jsx(PropField, { title: 'Opacity:', children: _jsx(SliderComponent, { propName: 'shadowOpacity', value: shadowOpacity, min: 0, max: 1, step: 0.01, onChange: onChange }) })] }), _jsx(DropDown, { title: 'Border', isOpen: openedTab === 'border', onClick: () => setOpenedTab(openedTab === 'border' ? 'none' : 'border') }), _jsxs("div", { className: 'editor-component-props-panel-dropdown-content-container', style: { display: openedTab === 'border' ? 'block' : 'none' }, children: [_jsx(PropField, { title: 'Width:', children: _jsx(NumberInput, { propName: 'borderWidth', value: borderWidth, min: 0, max: 999, onChange: onChange }) }), _jsx(PropField, { title: 'Style:', children: _jsx(SelectDropdown, { propName: 'borderStyle', value: localBorderStyle, withSearch: false, maxHeight: 1000, 
                            // @ts-ignore
                            values: Object.keys(BorderStyleEnum).map((a) => ({
                                value: BorderStyleEnum[a],
                                label: BorderStyleEnum[a],
                            })), onChange: onChange, onPreview: onPreview }) }), _jsx(PropField, { title: 'Color:', children: _jsx(ColorPicker, { value: borderColor, propName: 'borderColor', onChange: onChange }) }), _jsx(PropField, { title: 'Radius:', children: _jsx(SliderComponent, { propName: 'borderRadius', value: borderRadius, min: 0, max: Math.floor(Math.max(size.width, size.height) / 2), step: 1, 
                            // @ts-ignore
                            onChange: onChange }) })] })] }));
};
