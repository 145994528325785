import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Card, Grid, Layout, Page, Text } from '@shopify/polaris';
import CrispSpacer from '../../components/CrispSpacer.tsx';
import { useStore } from '../../stores';
import { navigate } from 'raviger';
import { predefinied_templates } from '../../Utils.tsx';
import TemplateCard from '../../components/Cards/TemplateCard.tsx';
const SelectTemplate = () => {
    const setEditTemplate = useStore((state) => state.setEditTemplate);
    return (_jsxs(Page, { title: 'Select template', subtitle: '', backAction: {
            content: 'Home',
            url: '#',
            onAction() {
                navigate('/');
            },
        }, children: [_jsx(Layout, { children: _jsxs(Layout.Section, { variant: 'fullWidth', children: [_jsxs(Card, { children: [_jsx(Text, { as: 'p', variant: 'headingMd', children: "On this page you can select predefined template to start with" }), _jsx("br", {}), _jsx(Button, { variant: 'primary', onClick: () => {
                                        navigate('/edit_template');
                                    }, children: "Start from scratch" })] }), _jsx("br", {}), _jsx(Grid, { children: predefinied_templates.map((t, i) => (_jsx(Grid.Cell, { columnSpan: { xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }, children: _jsx(TemplateCard, { is_select: true, data: { template_json: t }, onEditTemplate: () => {
                                        setEditTemplate({
                                            _id: null,
                                            name: Math.floor(Math.random() * 100000000).toString() +
                                                ' template',
                                            template_json: t,
                                        });
                                        navigate(`/edit_template/`);
                                    }, onTest: () => {
                                        navigate(`/integrate_template/`);
                                    } }) }, i))) })] }) }), _jsx(CrispSpacer, {})] }));
};
export default SelectTemplate;
