import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import EditorReadOnlyView from '../Editor/EditorReadOnlyView';
import { Button, Card, InlineStack } from '@shopify/polaris';
import { useEffect, useRef, useState } from 'react';
function TemplateCard({ onEditTemplate, onTest, is_select = false, data, }) {
    const [cardSizes, setCardSizes] = useState({
        width: data.template_json.width,
        height: data.template_json.height,
    });
    let containerRef = useRef(null);
    useEffect(function () {
        const calculateSizes = () => {
            if (containerRef && containerRef.current !== null) {
                setCardSizes({
                    width: containerRef.current.clientWidth,
                    height: Math.floor((containerRef.current.clientWidth * data.template_json.height) /
                        data.template_json.width),
                });
            }
        };
        calculateSizes();
        window.addEventListener('resize', calculateSizes);
        return () => {
            window.removeEventListener('resize', calculateSizes);
        };
    }, [containerRef.current, setCardSizes]);
    return (_jsx(Card, { roundedAbove: 'sm', children: _jsxs("div", { ref: containerRef, children: [_jsx("div", { children: _jsx("div", { style: {
                            width: `${cardSizes.width}px`,
                            height: `${cardSizes.height - 1}px`,
                            position: 'relative',
                        }, children: _jsx(EditorReadOnlyView, { scaleDirection: 'width', state: data.template_json.state, editorType: 'Template' }) }) }), _jsx("br", {}), _jsx("div", { style: { padding: '5px' }, children: is_select ? (_jsx(InlineStack, { align: 'start', gap: '200', children: _jsx(Button, { variant: 'primary', onClick: onEditTemplate, children: "Select template" }) })) : (_jsxs(InlineStack, { align: 'start', gap: '200', children: [_jsx(Button, { onClick: onTest, children: "Integrate template" }), _jsx(Button, { variant: 'primary', onClick: onEditTemplate, children: "Edit template" })] })) })] }) }));
}
export default TemplateCard;
