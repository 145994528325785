import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { getScaleTransform, getStyleForDraw } from '../../../../utils';
import { useCanvaZoomWithAction } from '../../../../../../context';
import './Container.scss';
export const Container = function Container({ id, isServer, position, size, rotation, selected, children, onSelect, startItemMoveAction, 
// @ts-ignore
startItemResizeAction, }) {
    const { canvaZoom } = useCanvaZoomWithAction();
    const stylesContainer = useMemo(function () {
        return getStyleForDraw({
            position: 'relative',
            transform: `rotate(${rotation}rad)`,
        }, position, size, canvaZoom);
    }, [position, size, canvaZoom, selected, rotation]);
    const stylesChildren = useMemo(function () {
        return getScaleTransform({
            transformOrigin: 'left top',
            position: 'absolute',
            left: '0',
            top: '0',
        }, size, canvaZoom);
    }, [canvaZoom, size]);
    return (_jsxs("div", { style: stylesContainer, onClick: (e) => {
            e.stopPropagation();
            e.preventDefault();
            if (!selected && !isServer) {
                onSelect();
            }
        }, onMouseDown: (e) => {
            if (selected) {
                startItemMoveAction(id, { x: e.clientX, y: e.clientY }, { ...position, ...size });
                e.stopPropagation();
            }
        }, children: [_jsx("div", { style: stylesChildren, children: children }), _jsx("div", { className: selected || isServer ? '' : 'canva-draw-container-hover-helper' })] }));
};
