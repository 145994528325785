import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo, useState, } from 'react';
import { ImageSizeMode } from '../../../../../../types';
import './Image.css';
import { getCssForBorder, getCssForShadow } from '../../../../../../utils';
import { useChangeDrawItem } from '../../../../../../context';
export const Image = function ImageDrawComponent({ item, isSelected, onSelect }) {
    const { id, opacity, imageSizeMode, imageType, link } = item;
    const { changeItem, modifyItemProperty } = useChangeDrawItem();
    let [containerSize, setContainerSize] = useState(item.size);
    let [imageSize, setImageSize] = useState(item.size);
    let [imageRect, setImageRect] = useState({
        x: 0,
        y: 0,
        ...item.size,
    });
    let onImageLoad = useCallback(function (e) {
        // @ts-ignore
        let width = e.target.naturalWidth;
        // @ts-ignore
        let height = e.target.naturalHeight;
        setImageSize({ width: width, height: height });
        if (imageSizeMode === ImageSizeMode.ContainResize) {
            const imageRatio = width / height;
            let rect;
            if (imageRatio >= containerSize.width / containerSize.height) {
                rect = {
                    x: 0,
                    y: (containerSize.height - containerSize.width / imageRatio) / 2,
                    width: containerSize.width,
                    height: containerSize.width / imageRatio,
                };
            }
            else {
                rect = {
                    x: (containerSize.width - containerSize.height * imageRatio) / 2,
                    y: 0,
                    width: containerSize.height * imageRatio,
                    height: containerSize.height,
                };
            }
            modifyItemProperty(item.id, 'size', {
                width: rect.width,
                height: rect.height,
            });
            modifyItemProperty(item.id, 'position', {
                x: item.position.x + rect.x,
                y: item.position.y + rect.y,
            });
        }
    }, [imageSizeMode, imageType]);
    useEffect(function () {
        setContainerSize(item.size);
    }, [item.size, item.borderWidth]);
    useEffect(function () {
        let rect;
        if (imageSizeMode === ImageSizeMode.Contain) {
            const imageRatio = imageSize.width / imageSize.height;
            if (imageRatio >= containerSize.width / containerSize.height) {
                rect = {
                    x: 0,
                    y: (containerSize.height - containerSize.width / imageRatio) / 2,
                    width: containerSize.width,
                    height: containerSize.width / imageRatio,
                };
            }
            else {
                rect = {
                    x: 0,
                    y: 0,
                    width: containerSize.height * imageRatio,
                    height: containerSize.height,
                };
            }
        }
        else if ([ImageSizeMode.Cover, ImageSizeMode.ContainResize].includes(imageSizeMode)) {
            const containerRatio = containerSize.width / containerSize.height;
            if (containerRatio >= imageSize.width / imageSize.height) {
                rect = {
                    x: 0,
                    y: (containerSize.height -
                        imageSize.height * (containerSize.width / imageSize.width)) /
                        2 -
                        item.borderWidth,
                    width: containerSize.width,
                    height: imageSize.height * (containerSize.width / imageSize.width),
                };
            }
            else {
                rect = {
                    x: -(containerSize.height -
                        imageSize.height * (containerSize.width / imageSize.width)) / 2,
                    y: 0,
                    width: imageSize.width * (containerSize.height / imageSize.height),
                    height: containerSize.height,
                };
            }
        }
        else if ([ImageSizeMode.ContainResizeLeft].includes(imageSizeMode)) {
            rect = {
                x: 0,
                y: 0,
                width: imageSize.width * (containerSize.height / imageSize.height),
                height: containerSize.height,
            };
        }
        else if ([ImageSizeMode.ContainResizeRight].includes(imageSizeMode)) {
            rect = {
                x: containerSize.width -
                    imageSize.width * (containerSize.height / imageSize.height),
                y: 0,
                width: imageSize.width * (containerSize.height / imageSize.height),
                height: containerSize.height,
            };
        }
        else {
            rect = { x: 0, y: 0, width: 0, height: 0 };
        }
        setImageRect(rect);
    }, [
        imageSizeMode,
        imageType,
        imageSize,
        containerSize,
        item.size,
        item.borderWidth,
    ]);
    let imageUrl = link;
    let containerClass = `canva-draw-image-draw`;
    const containerStyles = useMemo(function () {
        const str = {
            // padding: 10,
            userSelect: 'none',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            boxSizing: 'border-box',
            textAlign: item.imageSizeMode === ImageSizeMode.Contain
                ? 'center'
                : item.imageSizeMode === ImageSizeMode.ContainResizeLeft
                    ? 'left'
                    : 'none',
            backgroundColor: imageUrl && imageUrl.indexOf('product_image_placeholder.png') !== -1
                ? '#74FBEA'
                : undefined,
            // backgroundPosition: '50%',
            // backgroundImage: `url(${imageUrl})`,
            // backgroundRepeat: 'no-repeat',
            // backgroundSize: imageSizeMode !== ImageSizeMode.ContainResize ? imageSizeMode : 'contain',
            opacity: opacity,
            cursor: 'move',
            // ...getCssForBorder(item),
            ...getCssForShadow(item, false),
        };
        return str;
    }, [item, imageUrl]);
    const imageStyles = useMemo(function () {
        const str = {
            marginLeft: `${imageRect.x}px`,
            marginTop: `${imageRect.y}px`,
        };
        return str;
    }, [item, imageRect]);
    const imageBorderStyles = useMemo(function () {
        const str = {
            boxSizing: 'border-box',
            overflow: 'hidden',
            ...getCssForBorder(item),
        };
        return str;
    }, [item, imageRect]);
    function onImageError() {
        // @ts-ignore
        window.loadingError = true;
        setContainerSize({ width: 0, height: 0 });
    }
    return (_jsx("div", { className: containerClass, style: containerStyles, onClick: (e) => {
            if (!isSelected)
                onSelect();
            e.stopPropagation();
        }, children: _jsx("div", { style: imageBorderStyles, children: _jsx("img", { onError: onImageError, style: imageStyles, width: imageRect.width, height: imageRect.height, onLoad: onImageLoad, src: imageUrl }) }) }));
};
