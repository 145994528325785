import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import './TextInput.scss';
export const TextInput = function NumberInput({ propName, value, disabled, isTextAread, onChange }) {
    return (_jsx("div", { className: 'canva-props-panel-text-input-root', children: isTextAread ? (_jsx("textarea", { disabled: disabled, value: value, onChange: (e) => {
                onChange(propName, e.target.value);
            } })) : (_jsx("input", { type: 'text', disabled: disabled, value: value, onChange: (e) => {
                onChange(propName, e.target.value);
            } })) }));
};
