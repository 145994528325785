import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useLayoutEffect, useMemo, useRef, useState, } from 'react';
import { FontWeight, TextHorizontalAlignEnum, TextSmartCropEnum, TextVerticalAlignEnum, } from '../../../../../../types';
import { useChangeDrawItem, useFontsWithActions, } from '../../../../../../context';
import './Text.css';
import { getCssForBackground, getCssForBorder, getCssForPadding, getCssForShadow, } from '../../../../../../utils';
function textHorizontalAlignToStyle(align) {
    if (align === TextHorizontalAlignEnum.Center) {
        return 'center';
    }
    else if (align === TextHorizontalAlignEnum.Fill) {
        return 'justify';
    }
    else if (align === TextHorizontalAlignEnum.Left) {
        return 'left';
    }
    else if (align === TextHorizontalAlignEnum.Right) {
        return 'right';
    }
}
function textVerticalAlignToStyle(align) {
    if (align === TextVerticalAlignEnum.Top) {
        return 'flex-start';
    }
    else if (align === TextVerticalAlignEnum.Middle) {
        return 'space-around';
    }
    else if (align === TextVerticalAlignEnum.Bottom) {
        return 'flex-end';
    }
}
function fontWeightToNumber(fontWeight, font) {
    let availableWeights = font.weights;
    switch (fontWeight) {
        case FontWeight.Thin:
            if (availableWeights.includes(100))
                return 100;
            else if (availableWeights.includes(200))
                return 200;
            else if (availableWeights.includes(300))
                return 300;
            else if (availableWeights.includes(400))
                return 400;
            else
                return availableWeights[0];
        case FontWeight.ExtraLight:
            if (availableWeights.includes(200))
                return 200;
            else if (availableWeights.includes(100))
                return 100;
            else if (availableWeights.includes(300))
                return 300;
            break;
        case FontWeight.Light:
            if (availableWeights.includes(300))
                return 300;
            else if (availableWeights.includes(100))
                return 100;
            else if (availableWeights.includes(200))
                return 200;
            break;
        case FontWeight.Regular:
            if (availableWeights.includes(400))
                return 400;
            else if (availableWeights.includes(500))
                return 500;
            break;
        case FontWeight.Medium:
            if (availableWeights.includes(500))
                return 500;
            break;
        case FontWeight.SemiBold:
            if (availableWeights.includes(600))
                return 600;
            else if (availableWeights.includes(700))
                return 700;
            else if (availableWeights.includes(500))
                return 500;
            break;
        case FontWeight.Bold:
            if (availableWeights.includes(700))
                return 700;
            else if (availableWeights.includes(600))
                return 600;
            else if (availableWeights.includes(800))
                return 800;
            else if (availableWeights.includes(900))
                return 900;
            else if (availableWeights.includes(500))
                return 500;
            break;
        case FontWeight.ExtraBold:
            if (availableWeights.includes(800))
                return 800;
            else if (availableWeights.includes(700))
                return 700;
            else if (availableWeights.includes(600))
                return 600;
            else if (availableWeights.includes(900))
                return 900;
            else if (availableWeights.includes(500))
                return 500;
            break;
        case FontWeight.Black:
            if (availableWeights.includes(900))
                return 900;
            else if (availableWeights.includes(800))
                return 800;
            else if (availableWeights.includes(700))
                return 700;
            else if (availableWeights.includes(600))
                return 600;
            else if (availableWeights.includes(500))
                return 500;
            break;
    }
    //not found;
    if (availableWeights.includes(400))
        return 400;
    else
        return availableWeights[0];
}
export const Text = function TextDrawComponent({ item, disabled, isSelected, onSelect, onFocus, onBlur, containerSize, }) {
    const { id, opacity, text, fontFamily, fontWeight, fontSize, color, verticalAlign, horizontalAlign, verticalLetterSpacing, horizontalLetterSpacing, wrap, smartCrop, size, } = item;
    const ref = useRef(null);
    const [croppedText, setCroppedText] = useState(text);
    const [smartCroppedFontSize, setSmartCroppedFontSize] = useState(fontSize);
    const { fonts, loadFont } = useFontsWithActions();
    const font = fonts.find((f) => f.fontFamily === fontFamily);
    const fontWeightNumber = font ? fontWeightToNumber(fontWeight, font) : 400;
    const handleChange = (e) => {
        // @ts-ignore
        modifyItemProperty(id, 'text', e.target.value);
    };
    useEffect(function () {
        // let font = fonts.find(f => f.fontFamily === fontFamily && w.weight === fontWeightNumber);
        if (font && !font.loadedWeights.includes(fontWeightNumber)) {
            loadFont(font.fontFamily, fontWeightNumber);
        }
    }, [font, fontWeightNumber]);
    useEffect(() => {
        setCroppedText(text);
        setSmartCroppedFontSize(fontSize);
    }, [text, fontSize, smartCrop, size.width, size.height]);
    useLayoutEffect(function () {
        setTimeout(function () {
            if (!ref.current)
                return;
            if (smartCrop === TextSmartCropEnum.None || wrap) {
                return;
            }
            if (!font ||
                font.inLoading > 0 ||
                !font.loadedWeights.includes(fontWeightNumber)) {
                return;
            }
            if (ref.current.scrollHeight - 2 > containerSize.height) {
                if (smartCrop === TextSmartCropEnum.Crop) {
                    if (croppedText.split(' ').length > 1) {
                        let newCroppedText = croppedText
                            .split(' ')
                            .slice(0, -1)
                            .join(' ')
                            .trim();
                        setCroppedText(newCroppedText + '...');
                    }
                }
                else if (smartCrop === TextSmartCropEnum.Resize) {
                    if (smartCroppedFontSize > 6) {
                        setSmartCroppedFontSize(smartCroppedFontSize - 1);
                    }
                }
            }
        }, 1);
    }, [
        disabled,
        font,
        fontWeightNumber,
        smartCroppedFontSize,
        containerSize,
        smartCrop,
        wrap,
        croppedText,
    ]);
    const { modifyItemProperty } = useChangeDrawItem();
    const containerStyles = useMemo(function () {
        const str = {
            // padding: 10,
            padding: 0,
            userSelect: 'none',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            boxSizing: 'border-box',
            justifyContent: textVerticalAlignToStyle(verticalAlign),
            textAlign: textHorizontalAlignToStyle(horizontalAlign),
            zIndex: 5,
        };
        return str;
    }, [horizontalAlign, verticalAlign]);
    const textStyles = {
        overflow: smartCrop === TextSmartCropEnum.Resize ? 'initial' : 'hidden',
        display: 'inline-block',
        margin: horizontalAlign === TextHorizontalAlignEnum.Center ? '0 auto' : '0',
        fontWeight: fontWeightNumber,
        fontFamily: font ? font.cssName : 'Roboto',
        fontStyle: !!font && font.italic ? 'italic' : 'normal',
        color: color,
        opacity: opacity,
        fontSize: `${smartCroppedFontSize}px`,
        letterSpacing: `${horizontalLetterSpacing}px`,
        lineHeight: `${smartCroppedFontSize * 1.3 + verticalLetterSpacing}px`,
        whiteSpace: !!wrap ? 'nowrap' : 'normal',
        ...getCssForBackground(item),
        ...getCssForShadow(item, true),
        ...getCssForBorder(item),
        ...getCssForPadding(item),
        zIndex: 6,
    };
    return (_jsx("div", { className: 'canva-draw-text-draw', style: containerStyles, onClick: (e) => {
            if (!isSelected)
                onSelect();
            e.stopPropagation();
        }, children: smartCrop === TextSmartCropEnum.None ? (_jsx("div", { style: textStyles, ref: ref, dangerouslySetInnerHTML: { __html: text } })) : (_jsx("span", { ref: ref, style: textStyles, children: croppedText })) }));
};
