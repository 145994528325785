import { jsx as _jsx } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import './Rect.css';
import { getCssForBorder, getCssForShadow } from "../../../../../../utils";
export const Rect = function RectDrawComponent({ item, isSelected, onSelect }) {
    const containerStyles = useMemo(function () {
        const str = {
            userSelect: 'none',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: '100%',
            boxSizing: 'border-box',
            opacity: item.opacity,
            cursor: 'move',
            backgroundColor: item.color,
            ...getCssForBorder(item),
            ...getCssForShadow(item, false),
        };
        return str;
    }, [item]);
    return _jsx("div", { style: containerStyles, onClick: (e) => {
            if (!isSelected)
                onSelect();
            e.stopPropagation();
        } });
};
