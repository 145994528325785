import useSWR from 'swr';
import useFetch from './useFetch';
export const useTemplates = function () {
    const fetcher = useFetch();
    const { data, error, isLoading } = useSWR(`/api/user/template`, fetcher, {
        revalidateIfStale: true,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        errorRetryInterval: 20000,
        errorRetryCount: 100,
        refreshInterval: 0,
    });
    return [data, error, isLoading];
};
