import { create } from 'zustand';
import { SubscriptionPlanEnum } from '../types';
const store = (set) => ({
    admin_key: '',
    user: {
        _id: '',
        email: '',
        paid_until: 0,
        secret_key: '',
        sharding_key: 0,
        on_plan: SubscriptionPlanEnum.None,
        force_plan: SubscriptionPlanEnum.None,
        user_plan_versioning: 1,
        override_soft_limit: 0,
        override_hard_limit: 0,
    },
    edit_template: {
        _id: null,
        name: '',
        template_json: {
            width: 1200,
            height: 628,
            state: [],
        },
    },
    setEditTemplate: ({ _id, name, template_json, }) => set((state) => ({
        ...state,
        edit_template: {
            _id,
            name,
            template_json: template_json,
        },
    })),
    setUser: (user) => set((state) => ({
        ...state,
        user: {
            ...state.user,
            ...user,
        },
    })),
    setAdminKey: (new_admin_key) => set((state) => ({
        ...state,
        admin_key: new_admin_key,
    })),
});
export const useStore = create(store);
