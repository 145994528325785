import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from 'react';
import { useEditor } from '../../editor';
import Deserialize from './Deserialize.tsx';
function EditorReadOnlyView({ 
// @ts-ignore
state, scaleDirection = 'none', contentWidth = 1200, contentHeight = 628, templateData = {}, }) {
    let containerRef = useRef(null);
    let { EditorProvider, CanvaComponent } = useEditor({ width: contentWidth, height: contentHeight }, true);
    let [scale, setScale] = useState(1);
    useEffect(function () {
        const calculateSizes = () => {
            if (containerRef && containerRef.current !== null) {
                let scaleWidth = 
                // @ts-ignore
                (containerRef.current.clientWidth - 2) / contentWidth;
                let scaleHeight = 
                // @ts-ignore
                (containerRef.current.clientHeight - 2) / contentHeight;
                if (scaleDirection === 'width') {
                    setScale(scaleWidth);
                }
                else if (scaleDirection === 'height') {
                    setScale(scaleHeight);
                }
                else {
                    let idealScale = scaleWidth < scaleHeight ? scaleWidth : scaleHeight;
                    setScale(idealScale);
                }
            }
        };
        calculateSizes();
        window.addEventListener('resize', calculateSizes);
        return () => {
            window.removeEventListener('resize', calculateSizes);
        };
    }, [containerRef.current]);
    return (
    // @ts-ignore
    _jsx("div", { style: { width: '100%' }, ref: containerRef, children: _jsx("div", { style: {
                width: '100%',
                height: `${contentHeight * scale}px`,
            }, children: _jsx(EditorProvider, { errorModalCallback: () => { }, uploadImageCallback: () => Promise.resolve('not used'), children: _jsxs("div", { style: {
                        width: `${contentWidth}px`,
                        height: `${contentHeight}px`,
                        transform: `scale(${scale})`,
                        transformOrigin: 'top left',
                        border: '1px solid',
                        boxSizing: 'content-box',
                    }, children: [_jsx(Deserialize, { state: state }), CanvaComponent] }) }) }) }));
}
export default EditorReadOnlyView;
