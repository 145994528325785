export var FontWeight;
(function (FontWeight) {
    FontWeight["Thin"] = "Thin";
    FontWeight["ExtraLight"] = "Extra Light";
    FontWeight["Light"] = "Light";
    FontWeight["Regular"] = "Regular";
    FontWeight["Medium"] = "Medium";
    FontWeight["SemiBold"] = "Semi Bold";
    FontWeight["Bold"] = "Bold";
    FontWeight["ExtraBold"] = "Extra Bold";
    FontWeight["Black"] = "Black";
})(FontWeight || (FontWeight = {}));
export var ImageSizeMode;
(function (ImageSizeMode) {
    ImageSizeMode["Contain"] = "Contain";
    ImageSizeMode["Cover"] = "Cover";
    ImageSizeMode["ContainResize"] = "ContainResize";
    ImageSizeMode["ContainResizeLeft"] = "ContainResizeLeft";
    ImageSizeMode["ContainResizeRight"] = "ContainResizeRight";
})(ImageSizeMode || (ImageSizeMode = {}));
export var TextVerticalAlignEnum;
(function (TextVerticalAlignEnum) {
    TextVerticalAlignEnum["Top"] = "Top";
    TextVerticalAlignEnum["Middle"] = "Middle";
    TextVerticalAlignEnum["Bottom"] = "Bottom";
})(TextVerticalAlignEnum || (TextVerticalAlignEnum = {}));
export var TextHorizontalAlignEnum;
(function (TextHorizontalAlignEnum) {
    TextHorizontalAlignEnum["Left"] = "Left";
    TextHorizontalAlignEnum["Center"] = "Center";
    TextHorizontalAlignEnum["Right"] = "Right";
    TextHorizontalAlignEnum["Fill"] = "Fill";
})(TextHorizontalAlignEnum || (TextHorizontalAlignEnum = {}));
export var TextSmartCropEnum;
(function (TextSmartCropEnum) {
    TextSmartCropEnum["None"] = "None";
    TextSmartCropEnum["Resize"] = "Resize";
    TextSmartCropEnum["Crop"] = "Crop";
})(TextSmartCropEnum || (TextSmartCropEnum = {}));
export var DrawTypeEnum;
(function (DrawTypeEnum) {
    DrawTypeEnum["Text"] = "Text";
    DrawTypeEnum["Image"] = "Image";
    DrawTypeEnum["Rect"] = "Rect";
    DrawTypeEnum["Circle"] = "Circle";
})(DrawTypeEnum || (DrawTypeEnum = {}));
export const defaultShadow = {
    shadowColor: '#000000',
    shadowBlur: 0,
    shadowSpread: 0,
    shadowHorizontalOffset: 0,
    shadowVerticalOffset: 0,
    shadowOpacity: 1,
};
export var BorderStyleEnum;
(function (BorderStyleEnum) {
    BorderStyleEnum["Solid"] = "solid";
    BorderStyleEnum["Dashed"] = "dashed";
    BorderStyleEnum["Dotted"] = "dotted";
    BorderStyleEnum["Double"] = "double";
    BorderStyleEnum["Groove"] = "groove";
    BorderStyleEnum["Ridge"] = "ridge";
})(BorderStyleEnum || (BorderStyleEnum = {}));
export const defaultBorder = {
    borderColor: '#000000',
    borderWidth: 0,
    borderRadius: 0,
    borderStyle: BorderStyleEnum.Solid,
};
export const defaultPadding = {
    leftPadding: 0,
    rightPadding: 0,
    topPadding: 0,
    bottomPadding: 0,
};
export const defaultBackground = {
    backgroundColor: 'none',
    backgroundOpacity: 1,
};
export var ReducerTypes;
(function (ReducerTypes) {
    ReducerTypes[ReducerTypes["DrawReducer"] = 0] = "DrawReducer";
    ReducerTypes[ReducerTypes["CanvaZoomReducer"] = 1] = "CanvaZoomReducer";
    ReducerTypes[ReducerTypes["SelectedItemReducer"] = 2] = "SelectedItemReducer";
    ReducerTypes[ReducerTypes["FontReducer"] = 3] = "FontReducer";
})(ReducerTypes || (ReducerTypes = {}));
