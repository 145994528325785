export const unreachable = (data) => { };
function hexWithOpacityToRgba(hex, opacity) {
    let num = parseInt(hex.slice(1), 16);
    return `rgba(${(num & 0xff0000) >> 16}, ${(num & 0x00ff00) >> 8}, ${num & 0x0000ff}, ${opacity})`;
}
export function getCssForPadding(item) {
    return {
        padding: `${item.topPadding}px ${item.rightPadding}px ${item.bottomPadding}px ${item.leftPadding}px`
    };
}
export function getCssForBackground(item) {
    return {
        backgroundColor: item.backgroundColor !== 'none' ? hexWithOpacityToRgba(item.backgroundColor, item.backgroundOpacity) : 'none',
    };
}
export function getCssForBorder(item) {
    return {
        border: `${item.borderWidth}px ${item.borderStyle} ${item.borderColor}`,
        borderRadius: `${item.borderRadius}px`
    };
}
export function getCssForShadow(item, isText) {
    if (isText) {
        return {
            textShadow: `${item.shadowHorizontalOffset}px ${item.shadowVerticalOffset}px ${item.shadowBlur}px ${hexWithOpacityToRgba(item.shadowColor, item.shadowOpacity)}`,
        };
    }
    else {
        return {
            boxShadow: `${item.shadowHorizontalOffset}px ${item.shadowVerticalOffset}px ${item.shadowBlur}px ${item.shadowSpread}px ${hexWithOpacityToRgba(item.shadowColor, item.shadowOpacity)}`
        };
    }
}
