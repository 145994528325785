export var SelectedItemActionTypes;
(function (SelectedItemActionTypes) {
    SelectedItemActionTypes["Select"] = "SELECTED_ITEM_SELECT";
    SelectedItemActionTypes["Focus"] = "SELECTED_ITEM_FOCUS";
    SelectedItemActionTypes["Blur"] = "SELECTED_ITEM_BLUR";
    SelectedItemActionTypes["Deselect"] = "SELECTED_ITEM_DESELECT";
    SelectedItemActionTypes["StartMoveAction"] = "SELECTED_ITEM_START_MOVE_ACTION";
    SelectedItemActionTypes["StartResizeAction"] = "SELECTED_ITEM_START_RESIZE_ACTION";
    SelectedItemActionTypes["StartRotationAction"] = "SELECTED_ITEM_START_ROTATION_ACTION";
    SelectedItemActionTypes["StopAction"] = "SELECTED_ITEM_STOP_ACTION";
})(SelectedItemActionTypes || (SelectedItemActionTypes = {}));
// export type SelectedItemIdChangeAction = {
//     reducerType: ReducerTypes.SelectedItemIdReducer,
//     type: typeof SelectedItemIdActionType,
//     payload: number,
// }
export var SelectedItemActionsEnum;
(function (SelectedItemActionsEnum) {
    SelectedItemActionsEnum[SelectedItemActionsEnum["Idle"] = 0] = "Idle";
    SelectedItemActionsEnum[SelectedItemActionsEnum["ItemMove"] = 1] = "ItemMove";
    SelectedItemActionsEnum[SelectedItemActionsEnum["ResizeLeftTop"] = 2] = "ResizeLeftTop";
    SelectedItemActionsEnum[SelectedItemActionsEnum["ResizeTop"] = 3] = "ResizeTop";
    SelectedItemActionsEnum[SelectedItemActionsEnum["ResizeRightTop"] = 4] = "ResizeRightTop";
    SelectedItemActionsEnum[SelectedItemActionsEnum["ResizeRight"] = 5] = "ResizeRight";
    SelectedItemActionsEnum[SelectedItemActionsEnum["ResizeRightBottom"] = 6] = "ResizeRightBottom";
    SelectedItemActionsEnum[SelectedItemActionsEnum["ResizeBottom"] = 7] = "ResizeBottom";
    SelectedItemActionsEnum[SelectedItemActionsEnum["ResizeLeftBottom"] = 8] = "ResizeLeftBottom";
    SelectedItemActionsEnum[SelectedItemActionsEnum["ResizeLeft"] = 9] = "ResizeLeft";
    SelectedItemActionsEnum[SelectedItemActionsEnum["Rotate"] = 10] = "Rotate";
})(SelectedItemActionsEnum || (SelectedItemActionsEnum = {}));
export const selectedItemReducer = (state, action) => {
    switch (action.type) {
        case SelectedItemActionTypes.Select:
            return { ...state, id: action.payload.drawId, inFocus: false, currentAction: SelectedItemActionsEnum.Idle };
        case SelectedItemActionTypes.Deselect:
            return { ...state, id: -1, inFocus: false, currentAction: SelectedItemActionsEnum.Idle };
        case SelectedItemActionTypes.Focus:
            return { ...state, inFocus: true };
        case SelectedItemActionTypes.Blur:
            return { ...state, inFocus: false };
        case SelectedItemActionTypes.StopAction:
            return { ...state, currentAction: SelectedItemActionsEnum.Idle };
        case SelectedItemActionTypes.StartResizeAction:
            return { ...state, id: action.payload.drawId, currentAction: action.payload.resizeType, actionStartedRect: action.payload.startedRect, actionStartedMousePosition: action.payload.startedMousePosition };
        case SelectedItemActionTypes.StartMoveAction:
            return { ...state, id: action.payload.drawId, currentAction: SelectedItemActionsEnum.ItemMove, actionStartedMousePosition: action.payload.startedMousePosition, actionStartedRect: action.payload.startedRect };
        case SelectedItemActionTypes.StartRotationAction:
            return { ...state, id: action.payload.drawId, currentAction: SelectedItemActionsEnum.Rotate, actionStartedMousePosition: action.payload.startedMousePosition };
        default:
            return state;
    }
};
