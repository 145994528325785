import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Modal, Text } from '@shopify/polaris';
const usePromptModalHelper = (isOpen, promptMessage, promptType, promptButtonText, errorMessage, onPrompt, onClose) => {
    const [value, setValue] = useState('');
    const modal = (_jsx(Modal, { open: isOpen, onClose: onClose, title: promptMessage, primaryAction: {
            content: promptButtonText,
            onAction: () => {
                console.log('on action');
                onPrompt(value);
            },
        }, children: _jsxs(Modal.Section, { children: [_jsx("input", { type: promptType, value: value, onChange: (e) => setValue(e.target.value) }), errorMessage && (_jsx(Text, { as: 'span', variant: 'headingMd', tone: 'critical', children: errorMessage }))] }) }));
    return [modal];
};
export default usePromptModalHelper;
