import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { DrawTypeEnum } from "../../../../types";
import { Text } from "./components/Text";
import { Container } from "./components/Container";
import { useSelectedItemWithActions } from "../../../../context";
import { Image } from "./components/Image";
import { SelectedItemActionsEnum } from "../../../../reducers/selectedItemReducer";
import { Rect } from "./components/Rect";
import { Circle } from "./components/Circle";
export const DrawComponent = function ({ componentData, isServer }) {
    let { selectedItem, setSelectedItemId, startItemResizeAction, startItemMoveAction, selectedItemAction, stopSelectedItemAction, onFocus, onBlur } = useSelectedItemWithActions();
    if (isServer) {
        //TODO repair this hack
        setSelectedItemId = () => { };
        onFocus = () => { };
    }
    let component;
    switch (componentData.type) {
        case DrawTypeEnum.Text:
            component = _jsx(Text, { item: componentData, disabled: isServer, onSelect: () => setSelectedItemId(componentData.id), isSelected: !!selectedItem && selectedItem.id === componentData.id, onFocus: onFocus, onBlur: onBlur, containerSize: componentData.size });
            break;
        case DrawTypeEnum.Image:
            component = _jsx(Image, { item: componentData, onSelect: () => setSelectedItemId(componentData.id), isSelected: !!selectedItem && selectedItem.id === componentData.id });
            break;
        case DrawTypeEnum.Rect:
            component = _jsx(Rect, { item: componentData, onSelect: () => setSelectedItemId(componentData.id), isSelected: !!selectedItem && selectedItem.id === componentData.id });
            break;
        case DrawTypeEnum.Circle:
            component = _jsx(Circle, { item: componentData, onSelect: () => setSelectedItemId(componentData.id), isSelected: !!selectedItem && selectedItem.id === componentData.id });
            break;
        default:
            component = _jsx("div", { children: "Not realized" });
            break;
    }
    return _jsx(Container, { id: componentData.id, isServer: isServer, startItemMoveAction: startItemMoveAction, position: componentData.position, size: componentData.size, rotation: componentData.rotationAngle, selected: !!selectedItem && componentData.id === selectedItem.id, startItemResizeAction: startItemResizeAction, stopSelectedItemAction: stopSelectedItemAction, itemAction: selectedItem && selectedItem.id === componentData.id ? selectedItemAction : SelectedItemActionsEnum.Idle, onSelect: () => setSelectedItemId(componentData.id), children: component });
};
