import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { Modal, TextContainer } from "@shopify/polaris";
const useErrorHelper = () => {
    const [message, setMessage] = useState('');
    const modal = (_jsx(Modal, { open: !!message, onClose: () => setMessage(''), title: "Error", primaryAction: {
            content: 'Ok',
            onAction: () => setMessage(''),
        }, children: _jsx(Modal.Section, { children: _jsx(TextContainer, { children: _jsx("p", { children: message }) }) }) }));
    return [setMessage, modal];
};
export default useErrorHelper;
