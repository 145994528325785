import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { EditorProvider } from './context';
import { Canva } from './components/Canva';
import { ZoomSlider } from './components/ZoomSlider';
import { PropsPanel } from './components/PropsPanel';
import { ComponentsPanel } from './components/ComponentsPanel';
export function useEditor(canvaSize, isServer) {
    const CanvaComponent = useMemo(() => _jsx(Canva, { canvaSize: canvaSize, isServer: isServer }), [canvaSize]);
    const ZoomSliderComponent = useMemo(() => (isServer ? _jsx("div", {}) : _jsx(ZoomSlider, {})), []);
    const PropsPanelComponent = useMemo(() => (isServer ? _jsx("div", {}) : _jsx(PropsPanel, { direction: 'vertical' })), []);
    const ComponentsPanelComponent = useMemo(() => (isServer ? _jsx("div", {}) : _jsx(ComponentsPanel, { direction: 'vertical' })), []);
    return {
        EditorProvider,
        CanvaComponent,
        PropsPanelComponent,
        ComponentsPanelComponent,
        ZoomSliderComponent,
    };
}
