import { unreachable } from "../../utils";
export function getScaleTransform(initialStyles, size, multiplier) {
    return Object.assign({ ...initialStyles, }, {
        // 'transform': `rotate(1rad) translate(${size.width}px, ${size.height}px) scale(${multiplier})`,
        'transform': `scale(${multiplier})`,
        'width': `${size.width}px`,
        'height': `${size.height}px`
    });
}
export function getScaledStyle(initialStyles, size, multiplier) {
    return Object.assign({ ...initialStyles, }, { width: `${(size.width * multiplier)}px`, height: `${(size.height * multiplier)}px` });
}
export function getStyleForDraw(initialStyles, position, size, sizeMultiplier) {
    return getScaledStyle({
        ...initialStyles,
        'position': 'absolute',
        'left': `${position.x * sizeMultiplier}px`,
        'top': `${position.y * sizeMultiplier}px`,
    }, size, sizeMultiplier);
}
export var DrawSelectedLinePosition;
(function (DrawSelectedLinePosition) {
    DrawSelectedLinePosition[DrawSelectedLinePosition["Top"] = 0] = "Top";
    DrawSelectedLinePosition[DrawSelectedLinePosition["Right"] = 1] = "Right";
    DrawSelectedLinePosition[DrawSelectedLinePosition["Bottom"] = 2] = "Bottom";
    DrawSelectedLinePosition[DrawSelectedLinePosition["Left"] = 3] = "Left";
})(DrawSelectedLinePosition || (DrawSelectedLinePosition = {}));
export function getStylesForDrawSelectedLine(position, size, type, sizeMultiplier) {
    switch (type) {
        case DrawSelectedLinePosition.Top:
            return {
                left: `${-size.width / 2 * sizeMultiplier - 2}px`,
                top: `${-size.height / 2 * sizeMultiplier - 2}px`,
                width: `${size.width * sizeMultiplier + 4}px`,
                height: `${2}px`,
            };
        case DrawSelectedLinePosition.Right:
            return {
                right: `${-size.width / 2 * sizeMultiplier - 2}px`,
                top: `${-size.height / 2 * sizeMultiplier - 2}px`,
                width: `${2}px`,
                height: `${size.height * sizeMultiplier + 2}px`,
            };
        case DrawSelectedLinePosition.Bottom:
            return {
                left: `${-size.width / 2 * sizeMultiplier - 2}px`,
                bottom: `${-size.height / 2 * sizeMultiplier - 2}px`,
                width: `${size.width * sizeMultiplier + 4}px`,
                height: `${2}px`,
            };
        case DrawSelectedLinePosition.Left:
            return {
                left: `${-size.width / 2 * sizeMultiplier - 2}px`,
                top: `${-size.height / 2 * sizeMultiplier - 2}px`,
                width: `${2}px`,
                height: `${size.height * sizeMultiplier + 2}px`,
            };
        default:
            unreachable(type);
            return {};
    }
}
export var DrawResizeButtonPosition;
(function (DrawResizeButtonPosition) {
    DrawResizeButtonPosition[DrawResizeButtonPosition["LeftTop"] = 0] = "LeftTop";
    DrawResizeButtonPosition[DrawResizeButtonPosition["Top"] = 1] = "Top";
    DrawResizeButtonPosition[DrawResizeButtonPosition["RightTop"] = 2] = "RightTop";
    DrawResizeButtonPosition[DrawResizeButtonPosition["Right"] = 3] = "Right";
    DrawResizeButtonPosition[DrawResizeButtonPosition["RightBottom"] = 4] = "RightBottom";
    DrawResizeButtonPosition[DrawResizeButtonPosition["Bottom"] = 5] = "Bottom";
    DrawResizeButtonPosition[DrawResizeButtonPosition["LeftBottom"] = 6] = "LeftBottom";
    DrawResizeButtonPosition[DrawResizeButtonPosition["Left"] = 7] = "Left";
})(DrawResizeButtonPosition || (DrawResizeButtonPosition = {}));
export function getStylesDrawResizeButton(position, size, type, sizeMultiplier) {
    switch (type) {
        case DrawResizeButtonPosition.LeftTop:
            return {
                left: `${-size.width / 2 * sizeMultiplier - 7}px`,
                top: `${-size.height / 2 * sizeMultiplier - 7}px`,
                width: '12px',
                height: '12px',
                borderRadius: '50%',
                cursor: 'nwse-resize'
            };
        case DrawResizeButtonPosition.Top:
            return {
                left: `${-10}px`,
                top: `${-size.height / 2 * sizeMultiplier - 7}px`,
                width: '20px',
                height: '8px',
                cursor: 'ns-resize',
            };
        case DrawResizeButtonPosition.RightTop:
            return {
                right: `${-size.width / 2 * sizeMultiplier - 7}px`,
                top: `${-size.height / 2 * sizeMultiplier - 7}px`,
                width: '12px',
                height: '12px',
                borderRadius: '50%',
                cursor: 'nesw-resize',
            };
        case DrawResizeButtonPosition.Right:
            return {
                right: `${-size.width / 2 * sizeMultiplier - 6}px`,
                top: `${-10}px`,
                width: '8px',
                height: '20px',
                cursor: 'ew-resize',
            };
        case DrawResizeButtonPosition.RightBottom:
            return {
                right: `${-size.width / 2 * sizeMultiplier - 7}px`,
                bottom: `${-size.height / 2 * sizeMultiplier - 7}px`,
                width: '12px',
                height: '12px',
                borderRadius: '50%',
                cursor: 'nwse-resize',
            };
        case DrawResizeButtonPosition.Bottom:
            return {
                left: `${-10}px`,
                bottom: `${-size.height / 2 * sizeMultiplier - 7}px`,
                width: '20px',
                height: '8px',
                cursor: 'ns-resize',
            };
        case DrawResizeButtonPosition.LeftBottom:
            return {
                left: `${-size.width / 2 * sizeMultiplier - 7}px`,
                bottom: `${-size.height / 2 * sizeMultiplier - 7}px`,
                width: '12px',
                height: '12px',
                borderRadius: '50%',
                cursor: 'nesw-resize',
            };
        case DrawResizeButtonPosition.Left:
            return {
                left: `${-size.width / 2 * sizeMultiplier - 6}px`,
                top: `${-10}px`,
                width: '8px',
                height: '20px',
                cursor: 'ew-resize',
            };
        default:
            unreachable(type);
            return {};
    }
}
export function getStylesDrawRotateButton(position, size, type, sizeMultiplier) {
    return {
        position: "absolute",
        left: `-10px`,
        top: `${size.height / 2 * sizeMultiplier + 10}px`,
        width: '18px',
        height: '18px',
        borderRadius: '50%',
        cursor: 'pointer',
    };
}
