import useFetch from './useFetch.ts';
import { useCallback } from 'react';
export const useSaveTemplateRequest = function () {
    const fetcher = useFetch();
    return useCallback(function (id, name, data) {
        return fetcher(`/api/user/template/${id ? id : ''}`, {
            method: 'POST',
            body: JSON.stringify({
                name,
                version: '1.0',
                data,
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }, [fetcher]);
};
