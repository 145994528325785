import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useStore } from '../../stores';
import { useEffect, useRef, useState } from 'react';
import useErrorHelper from '../../hooks/useErrorHelper.tsx';
import { useImageFileUploader } from '../../hooks/useImageFileUploader.tsx';
import Deserialize from '../../components/Editor/Deserialize.tsx';
import { ArrowLeftIcon } from '@shopify/polaris-icons';
import { useEditor } from '../../editor';
import './edit_template.scss';
import SaveTemplateButton from '../../components/Editor/SaveTemplateButton.tsx';
import TemplateAddComponentButton from '../../components/Editor/TemplateAddComponentButton.tsx';
import { navigate, usePathParams } from 'raviger';
import TestTemplateButton from '../../components/Editor/TestTemplateButton.tsx';
import { useTemplate } from '../../hooks/useTemplate.tsx';
import { Spinner } from '@shopify/polaris';
const EditTemplate = () => {
    // const path = usePath();
    const props = usePathParams('/edit_template/:id');
    const [edit_template, error, isLoading] = useTemplate(props && props.id ? props.id : undefined);
    const setEditTemplate = useStore((state) => state.setEditTemplate);
    const [setErrorMessage, ErrorModal] = useErrorHelper();
    const { EditorProvider, CanvaComponent, ZoomSliderComponent, PropsPanelComponent, } = useEditor({ width: 1200, height: 628 }, false);
    const imageUploader = useImageFileUploader();
    const containerRef = useRef(null);
    // @ts-ignore
    const [appSize, setAppSize] = useState({
        // @ts-ignore
        width: document.body.clientWidth,
        // @ts-ignore
        height: document.body.clientHeight,
        vertical: false,
    });
    function resizeContainer() {
        // @ts-ignore
        let width = document.body.clientWidth;
        // @ts-ignore
        let height = document.body.clientHeight; // window.screen.availHeight; //containerRef.current.clientHeight;
        setAppSize({
            width,
            height,
            vertical: false,
        });
    }
    useEffect(() => {
        resizeContainer();
        window.addEventListener('resize', resizeContainer);
        return function () {
            window.removeEventListener('resize', resizeContainer);
        };
    }, [containerRef.current]);
    if (isLoading || error)
        return (_jsx("div", { style: { textAlign: 'center', marginTop: '20px' }, children: _jsx(Spinner, {}) }));
    return (_jsx("div", { className: 'app-editor-page', ref: containerRef, style: { minWidth: `100%`, minHeight: `100%`, background: 'white' }, children: _jsxs("div", { style: { width: `100%`, height: `${appSize.height}px` }, children: [ErrorModal, _jsx(EditorProvider, { uploadImageCallback: imageUploader, errorModalCallback: (err) => setErrorMessage(err), children: _jsxs("div", { children: [_jsx(Deserialize, { state: edit_template.template_json.state }), _jsxs("div", { className: 'app-editor-page-topbar-container', children: [_jsxs("div", { className: 'app-editor-page-topbar-left-buttons-container', children: [_jsx("div", { className: 'app-editor-page-topbar-back-button', onClick: () => {
                                                    navigate('/');
                                                }, children: _jsx(ArrowLeftIcon, { fill: 'black' }) }), _jsx(TemplateAddComponentButton, { errorModalCallback: (err) => setErrorMessage(err) })] }), _jsx("div", { className: 'app-editor-page-slider-container', children: _jsx("div", { className: 'app-editor-page-slider', children: ZoomSliderComponent }) }), _jsxs("div", { className: 'app-editor-page-topbar-right-buttons-container', children: [_jsx(TestTemplateButton, { id: edit_template._id, ok: (id) => {
                                                    navigate(`/edit_template/${id}`, { replace: true });
                                                    navigate(`/integrate_template/${id}?from_edit=true`);
                                                }, error: setErrorMessage }), _jsx(SaveTemplateButton, { id: edit_template._id, ok: () => {
                                                    navigate('/');
                                                }, error: setErrorMessage })] })] }), _jsxs("div", { className: 'app-editor-page-panels-container', style: { height: `${appSize.height - 47}px` }, children: [_jsx("div", { style: { width: `${appSize.width - 220}px`, height: `100%` }, children: CanvaComponent }), _jsx("div", { style: {
                                            width: `${220}px`,
                                            height: `100%`,
                                            borderLeft: 'solid 1px lightgrey',
                                            zIndex: 2,
                                            background: 'white',
                                        }, children: PropsPanelComponent })] })] }) })] }) }));
};
export default EditTemplate;
