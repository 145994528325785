import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import "./NumberInput.scss";
export const NumberInput = function NumberInput({ propName, value, min, max, onChange }) {
    return _jsx("div", { className: "canva-props-panel-number-input-root", children: _jsx("input", { type: 'number', value: '' + value, onChange: (e) => {
                // @ts-ignore
                let newValue = parseInt(e.target.value);
                if (!isNaN(newValue) && newValue >= min && newValue < max) {
                    onChange(propName, newValue);
                }
                else if (!isNaN(newValue) && newValue >= max) {
                    onChange(propName, newValue);
                }
                else {
                    onChange(propName, min);
                }
            } }) });
};
