import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import './ComponentsPanel.scss';
import { DropDown } from './components/DropDown';
import { AddComponentButton } from './components/AddComponentButton';
import { useDrawItemsWithActions } from '../../context';
export const ComponentsPanel = function ComponentsPanel({ direction }) {
    const [currentTab, setCurrentTab] = useState('components');
    const { addNewItem } = useDrawItemsWithActions();
    return (_jsxs("div", { className: 'editor-component-components-panel-root', children: [_jsx("div", { className: 'editor-component-components-dropdown-title-container', children: _jsx(DropDown, { title: 'Templates', isOpen: currentTab === 'templates', onClick: () => setCurrentTab(currentTab === 'templates' ? 'none' : 'templates') }) }), _jsx("div", { className: 'editor-component-components-dropdown-title-container', children: _jsx(DropDown, { title: 'Components', isOpen: currentTab === 'components', onClick: () => setCurrentTab(currentTab === 'components' ? 'none' : 'components') }) }), _jsx("div", { className: 'editor-component-components-dropdown-content-container', style: {
                    padding: '20px',
                    display: currentTab === 'components' ? 'block' : 'none',
                }, children: _jsxs("div", { className: 'editor-component-components-add-component-root', children: [_jsx(AddComponentButton, { addNewItem: addNewItem, type: 'text' }), _jsx(AddComponentButton, { addNewItem: addNewItem, type: 'image' }), _jsx(AddComponentButton, { addNewItem: addNewItem, type: 'circle' }), _jsx(AddComponentButton, { addNewItem: addNewItem, type: 'rect' })] }) })] }));
};
