import useFetch from './useFetch';
import { useCallback } from 'react';
export const useTemplateDelete = function (id) {
    const fetcher = useFetch();
    return useCallback(function () {
        console.log('DELETE TEMPLATE');
        return fetcher(`/api/user/template/${id}`, {
            method: 'DELETE',
            body: JSON.stringify({}),
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }, [fetcher]);
};
